import { useLocation, useNavigate } from "react-router-dom";
import {
  AllEmployeesHeading,
  MultiSelectButton,
  MultiSelectButtonSmall,
  ReportFilterBody,
  ReportFilterBodyHeading,
  ReportFilterContainer,
  ReportHeading,
  ReportHeadingContainer,
  ReportHorizontalSeparator,
  ReportSectionHeading,
  TransactionGrid,
} from "../ReportFilterPageStyles";
import {
  CommonModal,
  CustomButton,
  CustomDateField,
  Loader,
  NavigateBack,
} from "../../../components";
import { Constant } from "../../../config";
import { useEffect, useState } from "react";
import { Notify, Utils } from "../../../utils";
import { useAppSelector } from "../../../_app";
import { EmployeeService } from "../../../_service";
import { LoaderContainer } from "../../../CommonStyles";
import styled from "styled-components";
import EmployeeListModal from "../EmployeeListModal/EmployeeListModal";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { ReportService } from "../../../_service/ReportService";
import ReactPDF from "@react-pdf/renderer";
import PayslipExportPdf from "../ReportPdf/PayslipExportPdf";
import JSZip from "jszip";
import TransactionHistoryPdfDoc from "../TransactionHistoryPdf/TransactionHistoryPdfDoc";
import TransactionHistoryPdf from "../TransactionHistoryPdf/TransactionHistoryPdf";
import XLSX from "xlsx";

const pathToHeading = {
  "/dashboard/reports/employee_basic_info": "Employee Basic Info",
  "/dashboard/reports/payslip_export": "Payslip Exports",
  "/dashboard/reports/leave_expiry": "Leave Expiry",
} as any;

const TransactionHistoryExtraComp = () => {
  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );

  if (!currentDate) {
    currentDate = new Date();
  }
  currentDate.setDate(1);
  // Move back one day to the last day of the last month
  currentDate.setDate(0);
  const lastDayOfLastMonth = Utils._date(currentDate);

  // Set the date to the first day of the last month
  currentDate.setDate(1);
  const firstDayOfLastMonth = Utils._date(currentDate);

  const navigate = useNavigate();

  const typeIds = [
    "income",
    "deductions",
    "reimbursement",
    "summary",
    "other",
    "employer_contribution",
    "quantity",
  ];

  const { pathname } = useLocation();
  const [fromDate, setFromDate] = useState<string>(firstDayOfLastMonth);
  const [toDate, setToDate] = useState<string>(lastDayOfLastMonth);
  const [employeeList, setEmployeeList] = useState<any>({});
  const [filteredEmployeeList, setFilteredEmployeeList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any>([]);
  const [statusValue, setStatusValue] = useState<string>("1");
  const [combinePayslip, setCombinePayslip] = useState<boolean>(false);
  const [payslipData, setPayslipData] = useState<any>([]);
  const [transactionFilters, setTransactionFilters] = useState<any>([]);
  const [
    selectedTransactionFiltersOnlyItem,
    setSelectedTransactionFiltersOnlyItem,
  ] = useState<any>({});

  let subpath = pathname.split("/").pop();
  let reportTitle =
    subpath === "mpr_full"
      ? "Monthly Payroll Report - Full"
      : subpath === "mpr_gross"
      ? "Monthly Payroll Report - Gross Renumeration"
      : subpath === "mpr_drn"
      ? "Monthly Payroll Report - Deduction/Reimbursement/Net Pay"
      : "Transaction History Report";

      

  const user = useAppSelector((state) => state.user);
  const extractData = (data: any, query: string, filterObj: any) => {
    const pattern = /date_from=(\d{4}-\d{2}-\d{2})&date_to=(\d{4}-\d{2}-\d{2})/;
    const match = pattern.exec(query) as string[];
    const dateFrom = match[1];
    const dateTo = match[2];

    const unfilteredValuesObj = Object.values(filterObj).flat();
    const valuesObj = unfilteredValuesObj.filter(
      (field) => field !== null && field !== undefined
    );
    let headerArr = ["Employee", "Date", ...valuesObj];
    let valueArr: any = [];
    for (const employeeName in data) {
      const employeeData = data[employeeName];

      // Iterate through dates for each employee
      for (const date in employeeData) {
        const rowData = [employeeName, Utils._dateToShow(date)];

        // Extract values corresponding to fields
        valuesObj.forEach((field: any) => {
          const value = employeeData[date][field];
          rowData.push(
            value !== undefined ? Utils.getCommanSepartedNumericStringWithDollar(value) : "$0.00"
          );
        });

        valueArr.push(rowData);
      }
    }

    const firstColumnValues = valueArr.map((val: any) => val[0]);

    // Find the distinct values
    const distinctValues = [...new Set(firstColumnValues)];
    const employeeLength = distinctValues.length;

    const columnSums = Array(valueArr[0].length - 2).fill(0);

    valueArr.forEach((row: any) => {
      // Iterate starting from the third element (index 2) to the end
      for (let i = 2; i < row.length; i++) {
        // Convert the string to a float and add to the corresponding sum
        columnSums[i - 2] += parseFloat(
          row[i]?.replace("$", "")?.replace(",", "")
        );
      }
    });

    const totalArr = ["Total", ""].concat(
      columnSums.map((sum) => `${Utils.getCommanSepartedNumericStringWithDollar(sum)}`)
    );

    let selectedEmpNames = employeeList?.active.filter((emp:any)=> selectedEmployeeList?.includes(emp.id) ).map((item:any) => item.name)
    valueArr = valueArr.filter((item:any) => selectedEmpNames.includes(item[0]));

    console.log("finalValueArr pdf", valueArr, selectedEmpNames);

    return { dateFrom, dateTo, valueArr, headerArr, employeeLength, totalArr };
  };

  const extractDataForCSV = (data: any, query: string, filterObj: any) => {
    const pattern = /date_from=(\d{4}-\d{2}-\d{2})&date_to=(\d{4}-\d{2}-\d{2})/;
    const match = pattern.exec(query) as string[];
    const dateFrom = match[1];
    const dateTo = match[2];

    const unfilteredValuesObj = Object.values(filterObj).flat();
    const valuesObj = unfilteredValuesObj.filter(
      (field) => field !== null && field !== undefined
    );
    let headerArr = ["Employee", ...valuesObj];
    let valueArr: any = [];
    for (const employeeName in data) {
      const employeeData = data[employeeName];

      // Iterate through dates for each employee
      for (const date in employeeData) {
        const rowData = [employeeName];

        // Extract values corresponding to fields
        valuesObj.forEach((field: any) => {
          const value = employeeData[date][field];
          rowData.push(
            value !== undefined ? Utils.getCommanSepartedNumericStringWithDollar(value) : "$0.00"
          );
        });

        valueArr.push(rowData);
      }
    }

    const firstColumnValues = valueArr.map((val: any) => val[0]);

    // Find the distinct values
    const distinctValues = [...new Set(firstColumnValues)];
    const employeeLength = distinctValues.length;

    const columnSums = Array(valueArr[0].length - 2).fill(0);

    valueArr.forEach((row: any) => {
      // Iterate starting from the third element (index 2) to the end
      for (let i = 2; i < row.length; i++) {
        // Convert the string to a float and add to the corresponding sum
        columnSums[i - 2] += parseFloat(
          row[i]?.replace("$", "")?.replace(",", "")
        );
      }
    });

    const totalArr = ["Total", ""].concat(
      // columnSums.map((sum) => `$${sum.toFixed(2)}`)
      columnSums.map((sum) => `${Utils.getCommanSepartedNumericStringWithDollar(sum)}`)
    );

    let selectedEmpNames = employeeList?.active.filter((emp:any)=> selectedEmployeeList?.includes(emp.id) ).map((item:any) => item.name)
    valueArr = valueArr.filter((item:any) => selectedEmpNames.includes(item[0]));
    console.log("finalValueArr Ex", valueArr, selectedEmpNames);

    return { dateFrom, dateTo, valueArr, headerArr, employeeLength, totalArr };
  };

  const generatePdf = async (data: any, query: string, filterObj: any) => {
    const { dateFrom, dateTo, valueArr, headerArr, employeeLength, totalArr } =
      extractData(data, query, filterObj);

      let selectedEmpNames = employeeList?.active.filter((emp:any)=> selectedEmployeeList?.includes(emp.id) ).map((item:any) => item.name);
      let filteredData = Object.entries(data).filter((item:any)=> selectedEmpNames.includes(item[0]));
      

    const pdfBlob = await ReactPDF.pdf(
      <TransactionHistoryPdfDoc
        TransactionDocument={
          <TransactionHistoryPdf
            // payslipData={data}
            payslipData={Object.fromEntries(filteredData)}
            dateFrom={dateFrom}
            dateTo={dateTo}
            valueArr={valueArr}
            headerArr={headerArr}
            // employeeLength={employeeLength}
            employeeLength  = {selectedEmployeeList.length}
            companyName={user.defaultCompany}
            totalArr={totalArr}
          />
        }
      />
    ).toBlob();
    const url = URL.createObjectURL(pdfBlob as any);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${
      user.defaultCompany.split(" ")[0]
    }_transaction_history_report.pdf`; // Set the desired filename for the downloaded file
    document.body.appendChild(a);
    a.click();
  };

  const downloadCsv = (data: any, query: string, filterObj: any) => {
    const { dateFrom, dateTo, valueArr, headerArr, employeeLength, totalArr } =
      extractDataForCSV(data, query, filterObj);

    const csvTitle = [
      [`${user.defaultCompany}`],
      ["Transaction History Report"],
      [
        "Period",
        `${Utils._dateToShow(dateFrom)} to ${Utils._dateToShow(dateTo)}`,
      ],
      ["Number of Employees:", employeeLength],
    ];

    const combinedData = [
      ...csvTitle,
      [],
      [...headerArr],
      ...valueArr,
      [],
      [...totalArr],
    ];

    const workbook = XLSX.utils.book_new();

    // Add a worksheet with combined data
    const combinedWorksheet = XLSX.utils.aoa_to_sheet(combinedData);
    XLSX.utils.book_append_sheet(workbook, combinedWorksheet, "CombinedData");

    const numCols = 28; // Get the number of columns
    combinedWorksheet["!cols"] = Array(numCols).fill({ width: 15 });

    XLSX.writeFile(
      workbook,
      `${user.defaultCompany.split(" ")[0]}_transaction_history.xlsx`,
      {
        compression: true,
      }
    );
  };

  const handleExcelClick = (type: string = "excel") => {
    const quer = `company=${user.defaultCompnayId}&date_from=${fromDate}&date_to=${toDate}`;
    if (
      Object.keys(selectedTransactionFiltersOnlyItem).length &&
      selectedEmployeeList.length
    ) {
      if (Object.keys(payslipData)?.length > 0) {
        if (type === "excel") {
          downloadCsv(payslipData, quer, selectedTransactionFiltersOnlyItem);
        } else {
          generatePdf(payslipData, quer, selectedTransactionFiltersOnlyItem);
        }
      } else {
        Notify("No employees for this date range");
      }
    } else if (!Object.keys(selectedTransactionFiltersOnlyItem).length) {
      Notify("Please Select atleast one field", 0);
    } else if (!selectedEmployeeList.length) {
      Notify("Please Select atleast one employee", 0);
    }
  };

  const handleSelectAll = () => {
    const allEmpIds = employeeList?.active?.map((emp: any) => emp.id);
    setSelectedEmployeeList([...allEmpIds]);
  };

  const handleSelectEmployee = (id: any) => {
    if (selectedEmployeeList.includes(id)) {
      let interArr = selectedEmployeeList;
      interArr = interArr.filter((em: any) => em !== id);
      setSelectedEmployeeList(() => interArr);
    } else {
      setSelectedEmployeeList(() => [...selectedEmployeeList, id]);
    }
  };

  const handleEmployeeSelectNone = () => {
    setSelectedEmployeeList(() => []);
  };

  const handleSelect = (section_id: string, item_name: string) => {
    const prevObj = { ...selectedTransactionFiltersOnlyItem };
    if (
      typeof selectedTransactionFiltersOnlyItem === "object" &&
      selectedTransactionFiltersOnlyItem[section_id]?.includes(item_name)
    ) {
      let interArr = selectedTransactionFiltersOnlyItem[section_id];
      interArr = interArr.filter((em: any) => em !== item_name);
      prevObj[section_id] = interArr;
      setSelectedTransactionFiltersOnlyItem(() => prevObj);
    } else {
      prevObj[section_id] = [...(prevObj[section_id] || []), item_name];
      setSelectedTransactionFiltersOnlyItem(() => prevObj);
    }
  };

  const handleSelectAllTransactions = () => {
    const prevObj: any = {};
    typeIds.map((transactionFilterItem) => {
      const allItemArr = transactionFilters[transactionFilterItem]?.map(
        (filter: any) => filter?.name
      );
      prevObj[transactionFilterItem] = allItemArr;
    });
    setSelectedTransactionFiltersOnlyItem(() => prevObj);
  };

  const handleSelectNoneTransactions = () => {
    setSelectedTransactionFiltersOnlyItem(() => {});
  };

  const handleSectionFieldsSelectAll = (section: string) => {
    const prevObj: any = { ...selectedTransactionFiltersOnlyItem };
    const nameArr = transactionFilters[section].map(
      (filter: any) => filter?.name
    );
    prevObj[section] = nameArr;
    setSelectedTransactionFiltersOnlyItem(() => prevObj);
  };

  const handleSectionFieldsSelectNone = (section: string) => {
    let prevObj: any = { ...selectedTransactionFiltersOnlyItem };
    prevObj[section] = [];
    setSelectedTransactionFiltersOnlyItem(() => prevObj);
  };

  const transformToObjects = (list: any, ci = false) =>
    list.map((item: any) => ({ name: item, ci }));

  const getPayslipExportData = async () => {
    const searchQuery = `company=${user.defaultCompnayId}&date_from=${fromDate}&date_to=${toDate}`;
    setIsLoading(() => true);

    try {
      const { status, data } = await ReportService._getTransactionExportData(
        searchQuery
      );

      if (status === 200) {
        setEmployeeList(() => data.data.employee_list);
        setPayslipData(() => data.data.payslips);

        const resultObject: any = {};

        const keysArr = Array.from(
          new Set([
            ...Object.keys(data?.data?.transaction_list || {}),
            ...Object.keys(data?.data?.transaction_list_custom || {}),
          ])
        );

        keysArr.map((objKey: any) => {
          resultObject[objKey] = [
            ...transformToObjects(data?.data?.transaction_list[objKey] || []),
            ...transformToObjects(
              data?.data?.transaction_list_custom[objKey] || [],
              true
            ),
          ];
        });

        setTransactionFilters(() => resultObject);
      }
    } catch (err) {
      console.log(err);
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    if (Object.keys(employeeList).length > 0) {
      if (statusValue === "0") {
        setFilteredEmployeeList(() => [
          ...employeeList.active,
          ...employeeList.inactive,
        ]);
        setSelectedEmployeeList(() => [
          ...employeeList.active?.map((emp: any) => emp.id),
          ...employeeList.inactive?.map((emp: any) => emp.id),
        ]);
      } else if (statusValue === "1") {
        setFilteredEmployeeList(() => [...employeeList.active]);
        setSelectedEmployeeList(() =>
          employeeList.active?.map((emp: any) => emp.id)
        );
      } else {
        setFilteredEmployeeList(() => [...employeeList.inactive]);
        setSelectedEmployeeList(() =>
          employeeList.inactive?.map((emp: any) => emp.id)
        );
      }
    }
  }, [statusValue, employeeList]);

  useEffect(() => {
    getPayslipExportData();
  }, [fromDate, toDate]);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <><H3Heading>{reportTitle}</H3Heading>
      <ReportFilterContainer>
        <ReportHeadingContainer>
          <ReportHeading>{pathToHeading[pathname]}</ReportHeading>
        </ReportHeadingContainer>
        <ReportFilterBody>
          <div>
            <ReportSectionHeading>Filters</ReportSectionHeading>
            <div style={{ display: "flex", gap: 50, marginTop: 20 }}>
              <div style={{ display: "flex" }}>
                <Container>
                  <Text>Current Status </Text>
                  <Select
                    value={statusValue}
                    onChange={(e) => setStatusValue(e.target.value)}
                  >
                    <option value={"0"}>{Constant.common.all}</option>
                    <option value={"2"}>{Constant.common.inactive}</option>
                    <option value={"1"}>{Constant.common.active}</option>
                  </Select>
                </Container>
              </div>
              <div style={{ display: "flex" }}>
                <Container style={{ marginBottom: 20 }}>
                  <Text>Employees </Text>
                  <Information onClick={() => setShowEmployeeModal(() => true)}>
                    {selectedEmployeeList.length === 0
                      ? "None"
                      : selectedEmployeeList.length ===
                        employeeList.active.length +
                          employeeList.inactive.length
                      ? "All"
                      : `${selectedEmployeeList.length} of ${ statusValue == "0" ?
                          (employeeList.active.length + employeeList.inactive.length) : statusValue == "1" ? employeeList.active.length : employeeList.inactive.length
                        }`}
                  </Information>
                </Container>
              </div>
            </div>
          </div>
          <ReportHorizontalSeparator style={{ marginTop: 6 }} />
          <div>
            <ReportSectionHeading>Date Range</ReportSectionHeading>
            <div style={{ display: "flex", width: "50%" }}>
              <CustomDateField
                label="From"
                date={fromDate}
                setDate={(date: any) => setFromDate(() => date)}
                labelWidth="40px"
              />
              <CustomDateField
                label="To"
                date={toDate}
                setDate={(date: any) => setToDate(() => date)}
                labelWidth="20px"
              />
            </div>
          </div>
          <ReportHorizontalSeparator />
          <div>
            <ReportSectionHeading>Employees</ReportSectionHeading>
            <AllEmployeesHeading>
              {selectedEmployeeList.length === 0
                ? "No "
                : selectedEmployeeList.length ===
                  employeeList.active.length + employeeList.inactive.length
                ? `All (${selectedEmployeeList.length}) `
                : `${selectedEmployeeList.length} of ${
                  statusValue == "0" ?
                  (employeeList.active.length + employeeList.inactive.length) : statusValue == "1" ? employeeList.active.length : employeeList.inactive.length
                  } `}
              employee(s) selected
            </AllEmployeesHeading>
          </div>
          <ReportHorizontalSeparator />
          <div>
            <div style={{ display: "flex", gap: 20 }}>
              <ReportSectionHeading>Transactions</ReportSectionHeading>
              <MultiSelectButton onClick={() => handleSelectAllTransactions()}>
                All
              </MultiSelectButton>
              <MultiSelectButton onClick={() => handleSelectNoneTransactions()}>
                None
              </MultiSelectButton>
            </div>

            <div>
              {typeIds?.map(
                (transactionFilterSection: any) =>
                  transactionFilters[transactionFilterSection]?.length > 0 && (
                    <>
                      <div
                        style={{ display: "flex", gap: 10, margin: "20px 0" }}
                      >
                        <h3
                          style={{
                            fontWeight: 500,
                            fontSize: 20,
                            color: "#333333",
                          }}
                        >
                          {Utils.formateStringWithUnderscore(
                            transactionFilterSection
                          )}
                        </h3>

                        <MultiSelectButtonSmall
                          onClick={() =>
                            handleSectionFieldsSelectAll(
                              transactionFilterSection
                            )
                          }
                        >
                          All
                        </MultiSelectButtonSmall>
                        <MultiSelectButtonSmall
                          onClick={() =>
                            handleSectionFieldsSelectNone(
                              transactionFilterSection
                            )
                          }
                        >
                          None
                        </MultiSelectButtonSmall>
                      </div>
                      <TransactionGrid>
                        {transactionFilters[transactionFilterSection]?.map(
                          (filter: any) => (
                            <div
                              style={{
                                display: "flex",
                                gap: 20,
                                color: "#333333",
                              }}
                            >
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleSelect(
                                    transactionFilterSection,
                                    filter?.name
                                  )
                                }
                                checked={
                                  selectedTransactionFiltersOnlyItem &&
                                  selectedTransactionFiltersOnlyItem[
                                    transactionFilterSection
                                  ]?.includes(filter?.name)
                                }
                              />
                              <p>
                                {filter?.name}
                                {filter?.ci &&
                                  filter?.ci !== null &&
                                  " (Custom)"}
                              </p>
                            </div>
                          )
                        )}
                      </TransactionGrid>
                    </>
                  )
              )}
            </div>
          </div>
          <ReportHorizontalSeparator />
          <div style={{ display: "flex", gap: 20 }}>
            <CustomButton
              width="150px"
              title="Show Excel"
              onClick={() => handleExcelClick()}
            />

            <CustomButton
              width="150px"
              title="Show Pdf"
              onClick={() => handleExcelClick("pdf")}
            />

            <CustomButton
              type="secondary"
              title="Back"
              width="100px"
              onClick={() => navigate(-1)}
            />
          </div>
        </ReportFilterBody>
      </ReportFilterContainer>
      <CommonModal
        handleClose={() => setShowEmployeeModal(false)}
        open={showEmployeeModal}
        updateData={() => setShowEmployeeModal(false)}
        body={
          <EmployeeListModal
            employeeList={filteredEmployeeList}
            handleSelectAll={handleSelectAll}
            handleSelectNone={handleEmployeeSelectNone}
            selectedEmployeeList={selectedEmployeeList}
            handleSelectEmployee={handleSelectEmployee}
          />
        }
        heading="Filter Employees"
        conformButtonText="Close"
        isCancelButton={false}
      />
    </>
  );
};

export default TransactionHistoryExtraComp;

const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
const Text = styled.p`
  width: 110px;
  height: 30px;
  color: #fff;
  border: 1px solid #afb5ba;
  font-size: 0.8rem;
  background-color: #afb5ba;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;
const Select = styled.select`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
`;

const Information = styled.div`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
`;
