import React, { useEffect, useState } from "react";
import {
  AllEmployeesHeading,
  ReportFilterBody,
  ReportFilterContainer,
  ReportHeading,
  ReportHeadingContainer,
  ReportHorizontalSeparator,
  ReportSectionHeading,
  TransactionGrid,
} from "../ReportFilterPageStyles";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton, CustomDateField } from "../../../components";
import { useAppSelector } from "../../../_app";
import { Utils } from "../../../utils";
import { ReportService } from "../../../_service/ReportService";
import XLSX from 'xlsx';

const pathToHeading = {
  "/dashboard/reports/monthly_report": "Monthly Report",
  "/dashboard/reports/ytd_payroll_report": "YTD Payroll Report",
  "/dashboard/reports/ytd_by_employees_report": "YTD By Employees Report",
  "/dashboard/reports/ytd_by_company_report": "YTD By Company Report",
} as any;

const MonthlyReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );

  if (!currentDate) {
    currentDate = new Date();
  }
  currentDate.setDate(1);
  // Move back one day to the last day of the last month
  currentDate.setDate(0);
  const lastDayOfLastMonth = Utils._date(currentDate);

  // Set the date to the first day of the last month
  currentDate.setDate(1);
  const firstDayOfLastMonth = Utils._date(currentDate);

  const [fromDate, setFromDate] = useState<string>(firstDayOfLastMonth);
  const [toDate, setToDate] = useState<string>(lastDayOfLastMonth);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const [reportData, setReportData] = useState<any>({});

  const companyId =
    useAppSelector((state) => state.user.defaultCompnayId) || "";
  let reportCode = location?.pathname.split("/")?.pop() || "";

  const companyName = 
  useAppSelector((state) => state.user.defaultCompany) || "";

  const getData = async () => {
    if (companyId) {
      setIsLoading(() => true);
      try {
        const res = await ReportService._getCustomReport(
          reportCode,
          companyId,
          fromDate,
          toDate,
          selectedEmployees?.length > 0
            ? selectedEmployees?.join(selectedEmployees)
            : ""
        );
        if (res.status === 200) {
          let data = res?.data?.data;

          if (!res?.data?.data?.is_custom_report_settings) {
            navigate("/dashboard/manage-report");
          }

          setReportData(res?.data?.data);
        }
      } catch (e: any) {
        console.log("err", e);
      }
      setIsLoading(() => false);
    }
  };

  useEffect(() => {
    getData();
  }, [fromDate, toDate, companyId, selectedEmployees]);

  const downloadExcel = () => {
    console.log("download excel report")
    const csvTitle = [
      [`${reportData?.report_meta_data?.company_name}`],
      ["Monthly Report"],
      [
        "Period",
        `${Utils._dateToShow(fromDate)} to ${Utils._dateToShow(toDate)}`,
      ],
      ["Number of Employees:", reportData?.report_meta_data?.no_of_employees],
    ];

    let valueArr = reportData?.report_data?.map( (item:any) => Object.values(item));
    let totalArr = Object.values(reportData?.report_totals)
      

    console.log("theFinalArrays", {valueArr, totalArr, csvTitle})

    const combinedData = [
      ...csvTitle,
      [],
      [...reportData?.report_headers],
      ...valueArr,
      [],
      ["TOTAL",...totalArr],
    ];

    const workbook = XLSX.utils.book_new();

    // Add a worksheet with combined data
    const combinedWorksheet = XLSX.utils.aoa_to_sheet(combinedData);
    XLSX.utils.book_append_sheet(workbook, combinedWorksheet, "CombinedData");

    const numCols = 28; // Get the number of columns
    combinedWorksheet["!cols"] = Array(numCols).fill({ width: 15 });

    XLSX.writeFile(
      workbook,
      `${reportData?.report_meta_data?.company_name}_monthly_report.xlsx`,
      {
        compression: true,
      }
    );
  }

  console.log("inside monthlyReport", reportCode, reportData);
  console.log("thePath", location.pathname.split("/").pop());

  return (
    <>
      <H3Heading>{"Monthly Report"}</H3Heading>
      <ReportFilterContainer>
        <ReportHeadingContainer>
          {/* <ReportHeading>{pathToHeading[location.pathname]}</ReportHeading> */}
        </ReportHeadingContainer>

        <ReportFilterBody>
          <ReportHorizontalSeparator style={{ marginTop: 6 }} />
          <div>
            <ReportSectionHeading>Date Range</ReportSectionHeading>
            <div style={{ display: "flex", width: "50%" }}>
              <CustomDateField
                label="From"
                date={fromDate}
                setDate={(date: any) => setFromDate(() => date)}
                labelWidth="40px"
              />
              <CustomDateField
                label="To"
                date={toDate}
                setDate={(date: any) => setToDate(() => date)}
                labelWidth="20px"
              />
            </div>
          </div>
          <ReportHorizontalSeparator />

          <ReportSectionHeading>Employees</ReportSectionHeading>

          <AllEmployeesHeading>
            {selectedEmployees.length === 0
              ? "No "
              : selectedEmployees.length ===
                reportData?.employee_list.active.length +
                  reportData?.employee_list.inactive.length
              ? `All (${selectedEmployees.length}) `
              : `${selectedEmployees.length} of ${
                  reportData?.employee_list.active.length +
                  reportData?.employee_list.inactive.length
                } `}
            employee(s) selected
          </AllEmployeesHeading>

          <ReportHorizontalSeparator />

          <ReportSectionHeading>Transactions</ReportSectionHeading>

          <div>
            {Object.entries(reportData?.parameters || {})?.map((item: any) => (
              <>
                <div style={{ display: "flex", gap: 10, margin: "20px 0" }}>
                  <h3
                    style={{
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#333333",
                    }}
                  >
                    {Utils.formateStringWithUnderscore(item[0])}
                  </h3>
                </div>
                <TransactionGrid>
                  {item[1]?.map((x: any) => (
                    <div
                      style={{
                        display: "flex",
                        gap: 20,
                        color: "#333333",
                      }}
                    >
                      <input type="checkbox" checked={x?.is_checked} />
                      <p>{x?.name}</p>
                    </div>
                  ))}
                </TransactionGrid>
              </>
            ))}
          </div>
          <ReportHorizontalSeparator />
          <CustomButton
              width="150px"
              title="Show Excel"
              onClick={downloadExcel}
            />
        </ReportFilterBody>
      </ReportFilterContainer>
    </>
  );
};

export default MonthlyReport;
