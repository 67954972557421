import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextField,
  NavigateBack,
} from "../../../components";
import { Heading } from "../../appSettings/AppSettingsStyles";
import { Notify } from "../../../utils";
import { Constant } from "../../../config";
import { RoleService } from "../../../_service";
import { useLocation, useNavigate } from "react-router-dom";

const AddGroup = () => {
  const [groupName, setGroupName] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  const addGroup = async () => {
    try {
      const { status } = await RoleService._addRole(groupName);
      if (status === 201) {
        Notify("Group Added Successfully", 1);
        navigate("/dashboard/manage-users/groups");
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const editGroup = async () => {
    try {
      const { status } = await RoleService._editRole(
        location.state.id,
        groupName,
      );
      if (status === 200) {
        Notify("Group edited Successfully", 1);
        navigate("/dashboard/manage-users/groups");
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const saveButtonClick = () => {
    if (location.pathname.split("/").pop() === "edit") {
      editGroup();
    } else {
      addGroup();
    }
  };

  useEffect(() => {
    if (location.pathname.split("/").pop() === "edit") {
      setGroupName(() => location.state.name);
    }
  }, []);
  return (
    <div>
      <NavigateBack />
      <Heading>Add Group</Heading>
      <div style={{ margin: "40px 16px" }}>
        <CustomTextField
          title="Group Name"
          value={groupName}
          onChangeText={setGroupName}
        />
      </div>
      <CustomButton
        title="Save"
        onClick={saveButtonClick}
        disable={groupName.length <= 0}
      />
    </div>
  );
};

export default AddGroup;
