import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

// User data types
interface Props {
  menu: any;
  email: string;
  id: number;
  name: string;
  refresh: string;
  access: string;
  defaultCompany: string;
  defaultCompnayId: string;
  defaultCompanyPayrunStartDate: string;
  actualDefaultCompanyId: string;
  lastLoggedInTime: any;
  isSessionActive: boolean;
  groups: any;
  permission: any;
  current_payrun_date: any;
  filing_date: any;
  employee_id: any;
}

// initial state of user's data
const initialState: Props = {
  email: "",
  id: -1,
  name: "",
  refresh: "",
  access: "",
  defaultCompany: "",
  defaultCompnayId: "",
  defaultCompanyPayrunStartDate: "",
  actualDefaultCompanyId: "",
  lastLoggedInTime: null,
  isSessionActive: false,
  menu: [],
  groups: [],
  permission: "full",
  current_payrun_date: "",
  filing_date: "",
  employee_id: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // reset the state
    resetUser: () => initialState,
    // update intial state of user data
    updateUserIntialState: (state: Props, action: PayloadAction<any>) => {
      current(state);
      state = Object.assign(state, action.payload);
    },
  },
});

export const { updateUserIntialState, resetUser } = userSlice.actions;
export default userSlice.reducer;
