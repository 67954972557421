import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton, Loader, NavigateBack } from "../../components";
import {
  CustomTextField,
  CustomSelectField,
} from "../../components/customFormField";
import { Notify } from "../../utils";
import { CompanyService, RoleService, UserService } from "../../_service";

import "./style.css";
import { CopyText } from "./styles";
import { FormSubmitButtonContainer, LoaderContainer } from "../../CommonStyles";
import { Chip } from "../../components/chip";
import validator from "validator";

interface CompanyData {
  company_id: number | string;
  name: string;
  access_type: "full" | "read" | "none";
}

interface CompanyResponse {
  id: number;
  name: string;
}

interface RoleData {
  id: number;
  name: string;
}

const RESTRICT_ACCESS_OPTIONS = [
  {
    name: "Full",
    value: "full",
  },
  {
    name: "Read Only",
    value: "read",
  },
  {
    name: "None",
    value: "none",
  },
];

const AddUsers = () => {
  const { state } = useLocation();
  const [roles, setRoles] = useState<RoleData[]>([]);
  const [isCompanyRestrict, setIsCompanyRestrict] = useState(false);
  const [companies, setCompanies] = useState<CompanyData[]>([]);

  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userRole, setUserRole] = useState<any>([]);
  const [defaultAccess, setDefaultAccess] = useState<"full" | "read" | "none">(
    "full"
  );
  const [isDisplayCopyMessage, setIsDisplayCopyMessage] = useState(false);

  const [isEmailError, setIsEmailError] = useState(false);
  const [isFirstNameError, setIsFirstNameError] = useState(false);
  const [isLastNameError, setIsLastNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [userId, setUserId] = useState<any>(null)

  const emailPattern = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

  const navigate = useNavigate();

  const getAllRoles = async () => {
    try {
      const res = await RoleService._getRoles();
      if (res.status === 200) {
        setRoles(() => [...res.data]);
      }
    } catch (e: any) {
      Notify("Something went wrong, please try again!");
    }
  };

  const getUserInformation = async () => {
    setIsLoading(() => true);
    const res = await UserService._getUserInformation(state?.userId);
    if (res.status === 200) {
      setUserEmail(() => res.data.email);
      setUserFirstName(() => res.data.first_name);
      setUserLastName(() => res.data.last_name);
      setUserRole(() => res.data.groups.map((group: any) => group.id) || []);
      setIsCompanyRestrict(() => res.data.restricted);
      setCompanies(() => res.data.restricted_company);
    }
    setIsLoading(() => false);
  };

  const checkIfEmailExist = async (email: string) => {
    const res = await UserService._checkUserExist(email);
    if (res.status === 200) {
      console.log("checkEmail res", res?.data?.data, res?.data?.data?.user_data);

      if(res?.data?.data?.is_exists) {
        setIsEdit(()=>true);
        setUserId(()=>res?.data?.data?.user_data?.id)
        setUserEmail(() => res?.data?.data?.user_data?.email);
        setUserFirstName(() => res?.data?.data?.user_data?.first_name);
        setUserLastName(() => res?.data?.data?.user_data?.last_name);
        setUserRole(() => res?.data?.data?.user_data?.groups.map((group: any) => group.id) || []);
        setIsCompanyRestrict(() => res?.data?.data?.user_data?.restricted);
        setCompanies(() => res?.data?.data?.user_data?.restricted_company);
      } else {
        setIsEdit(()=> false);
        setUserId(()=> null)
      }
    }

  }

  const setAllCompanyAccess = async () => {
    try {
      const res = await CompanyService._getAllComanies(1);

      const companyAccess = res.data.map((company: CompanyResponse) => ({
        company_id: company.id,
        access_type: "full",
        name: company.name,
      }));
      setCompanies(companyAccess);
    } catch (e: any) {
      Notify("Some Error Occurred");
    }
  };

  const toggleRestrictCompany = async () => {
    if (!isCompanyRestrict) {
      setIsCompanyRestrict(() => true);
      await setAllCompanyAccess();
    } else {
      setIsCompanyRestrict(() => false);
    }
  };

  // const changeUserRole = (value: string) => {
  //   if (parseInt(value) === 0) {
  //     setIsRoleError(true);
  //     setUserRole(() => parseInt(value));
  //   } else {
  //     setUserRole(() => parseInt(value));
  //     setIsRoleError(false);
  //   }
  // };

  const createUser = async (e: any) => {
    e.preventDefault();

    // if (!emailPattern.test(userEmail)) {
    //   Notify("Please Enter a valid Email");
    //   setIsEmailError(true);
    //   return;
    // }
    if (!userFirstName) {
      Notify("Please Enter a valid First name");
      setIsFirstNameError(true);
      return;
    }
    // if (!userLastName) {
    //   Notify("Please Enter a valid Last name");
    //   setIsLastNameError(true);
    //   return;
    // }

    if (!validator.isEmail(userEmail)) {
      Notify("Please Enter a valid Email");
      setIsEmailError(true);
      return;
    }

    if (userRole.length <= 0) {
      Notify("Please Enter a valid Role");
      return;
    }

    const userCreateData = {
      first_name: userFirstName,
      last_name: userLastName,
      email: userEmail,
      groups: userRole,
      restricted: isCompanyRestrict,
      companies_access: companies.map(({ company_id, access_type }) => ({
        company_id: parseInt(company_id as any),
        access_type,
      })),
    };

      try {
        if (state?.userId || isEdit) {
          console.log("BeforeUpdate", isEdit, userId, userCreateData)
          const res = await UserService._updateUser(isEdit ? userId : state.userId, userCreateData);
          if (res.status >= 200 && res.status < 300) {
            Notify("User Updated Successfully", 1);
            navigate("/dashboard/manage-users");
          }
        } else {
          const res = await UserService._createUser(userCreateData);
          if (res.status >= 200 && res.status < 300) {
            Notify("User Created Successfully", 1);
            navigate("/dashboard/manage-users");
          }
        }
      } catch (error) {
        Notify("Something went wrong, please try again later");
      }
  };

  const copyAccess = () => {
    setIsDisplayCopyMessage(() => false);
    const updatedCompanyAccess = companies.map((company) => ({
      ...company,
      access_type: defaultAccess,
    }));

    setCompanies(() => updatedCompanyAccess);
  };

  const updateCompanyAccess = (value: "read" | "full" | "none", id: number | string) => {
    const updatedArray = companies.map((obj) => {
      if (obj.company_id === id) {
        return { ...obj, access_type: value };
      }
      return obj;
    });

    setCompanies(() => updatedArray);
  };

  const changeDefaultAccess = (value: any) => {
    setDefaultAccess(() => value);
    setIsDisplayCopyMessage(() => true);
  };

  const toggleSelectedGroup = (e: any, groupId: any) => {
    e.preventDefault();

    const groupIndex = userRole.findIndex((roleId: any) => roleId === groupId);
    let groupData = [...userRole];

    if (groupIndex === -1) {
      groupData = [...groupData, groupId];
    } else {
      groupData.splice(groupIndex, 1);
    }
    setUserRole(() => groupData);
  };

  const validateEmail = () => {
    setIsEmailError(!emailPattern.test(userEmail));
  };

  const validateFirstName = () => {
    setIsFirstNameError(!userFirstName);
  };

  const validateLastName = () => {
    setIsLastNameError(!userLastName);
  };

  useEffect(() => {
    getAllRoles();
    if (state?.userId) {
      getUserInformation();
    }
  }, []);

  console.log('stateData', {
    userEmail,
    userFirstName,
    userLastName,
    isCompanyRestrict,
    userRole,
    isEdit,
    userId,
    state
  })

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <NavigateBack />
          <div>
            <form className="role-form">
              <CustomTextField
                title="Email"
                value={userEmail}
                onChangeText={setUserEmail}
                error={isEmailError ? "Please Enter a valid email" : undefined}
                onBlur={()=> {
                  validateEmail();
                  checkIfEmailExist(userEmail);
                }
              }
              />
              <CustomTextField
                title="First Name"
                value={userFirstName}
                onChangeText={setUserFirstName}
                error={
                  isFirstNameError
                    ? "Please Enter a valid first name"
                    : undefined
                }
                onBlur={validateFirstName}
              />
              <CustomTextField
                title="Last Name"
                value={userLastName}
                onChangeText={setUserLastName}
                error={
                  isLastNameError ? "Please Enter a valid last name" : undefined
                }
                // onBlur={validateLastName}
              />
              <div style={{ display: "flex", marginTop: "0.8rem" }}>
                <p
                  style={{
                    color: "#495057",
                    width: "30%",
                    flex: 3,
                    maxWidth: 250,
                  }}
                >
                  Permission Role
                </p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: 20,
                    width: "60%",
                  }}
                >
                  {/* {roles.map((role) => (
                    <Chip
                      title={role.name}
                      onClick={(e) => toggleSelectedGroup(e, role.id)}
                      isSelected={userRole.includes(role.id)}
                    />
                  ))} */}

                  {roles?.filter((item :any)=> item?.name != "Employees").map((role) => (
                    <Chip
                      title={role.name}
                      onClick={(e) => toggleSelectedGroup(e, role.id)}
                      isSelected={userRole.includes(role.id)}
                    />
                  ))}
                </div>
              </div>
              {/* <CustomSelectField
                title="Permission Role"
                value={userRole}
                onChangeOption={(value) => changeUserRole(value)}
                option={roles.map((role) => ({
                  key: role.name,
                  value: role.id,
                }))}
                error={isRoleError ? "Please add a valid Role" : undefined}
              /> */}
              <div style={{ marginTop: "20px", marginBottom: 20 }}>
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  onChange={toggleRestrictCompany}
                  checked={isCompanyRestrict}
                />
                <span>Restrict to selected Companies</span>
              </div>

              {isCompanyRestrict && (
                <>
                  <CustomSelectField
                    title="Default Access Role"
                    value={defaultAccess}
                    onChangeOption={(value: any) => changeDefaultAccess(value)}
                    option={RESTRICT_ACCESS_OPTIONS.map((option) => ({
                      key: option.name,
                      value: option.value,
                    }))}
                  />
                  {isDisplayCopyMessage && (
                    <CopyText onClick={copyAccess}>
                      (Copy to all companies)
                    </CopyText>
                  )}
                  {companies.map((company) => (
                    <CustomSelectField
                      title={company.name}
                      value={company.access_type}
                      onChangeOption={(value: any) =>
                        updateCompanyAccess(value, company.company_id)
                      }
                      option={RESTRICT_ACCESS_OPTIONS.map((option) => ({
                        key: option.name,
                        value: option.value,
                      }))}
                    />
                  ))}
                </>
              )}
              <FormSubmitButtonContainer>
                <CustomButton
                  onClick={() => navigate("/dashboard/manage-users")}
                  title="Cancel"
                  type="secondary"
                />
                <CustomButton onClick={createUser} title="Save" />
              </FormSubmitButtonContainer>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default AddUsers;
