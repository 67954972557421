import {
  AddressInterface,
  BankInfoInterface,
  EmployeeInfoInterface,
  LegalStatusInterface,
} from "../_types/AddEmployeeInterface";
import { Utils } from "./utils";

import validator from "validator";

const validateNRIC = (nric: any) => {
  if (Utils.isEmpty(nric)) {
    return "NRIC cannot be empty";
  } else if (
    !(
      nric?.toLocaleUpperCase().startsWith("S") ||
      nric?.toLocaleUpperCase().startsWith("T")
    )
  ) {
    return "must begin with either S or T";
  }
  return "";
};

const validateFIN = (fin: any) => {
  if (Utils.isEmpty(fin)) {
    return "Fin cannot be empty";
  } else if (
    !(
      fin?.toLocaleUpperCase().startsWith("F") ||
      fin?.toLocaleUpperCase().startsWith("G") ||
      fin?.toLocaleUpperCase().startsWith("M")
    )
  ) {
    return "must begin with either F or G or M";
  }
  return "";
};
const validateMalaysianIC = (malaysian_ic: any) => {
  if (Utils.isEmpty(malaysian_ic)) {
    return "Malaysian IC cannot be empty";
  } else if (malaysian_ic.length > 12) {
    return "is too long (maximum is 12 characters)";
  } else if (malaysian_ic.length < 7) {
    return "is too short (minimum is 7 characters)";
  }
  return "";
};

const phoneValidate = (val: any) => {
  if (Utils.isEmpty(val)) {
    return "Phone Number cannot be empty";
  } else if (val.length < 8) {
    return "is too short (minimum is 8 digit)";
  }
  return "";
};
const nationalityValidate = (nationality: any) => {
  if (Utils.isEmpty(nationality)) {
    return "Nationality cannot be empty";
  } 
  };

const validateEmail = (email: any) => {

  if (!email) {
    return "Email field cannot be empty!"
  } else
  if (!validator.isEmail(email)) {
    return "Enter a valid Email!";
  } else {
    return "";
  }
};


const addEmployeeDataValidate = (
  employeeInfo: EmployeeInfoInterface,
  legalStatusInfo: LegalStatusInterface,
  addressInfo: AddressInterface,
  bankInfo: BankInfoInterface,
  updatedErrorByKey: (item: any, item1: any) => void,
  manualEmpNumber: boolean = false
) => {
  const error: { [key: string]: string } = {};
  console.log("error",error)
  try {
    if (employeeInfo.name_format !== "Single Name") {
      if (Utils.isEmpty(employeeInfo.first_name)) {
        error.first_name = "First name cannot be empty";
      }
      if (Utils.isEmpty(employeeInfo.last_name)) {
        error.last_name = "Last name cannot be empty";
      }
    }
    if (employeeInfo.name_format === "Single Name") {
      if (Utils.isEmpty(employeeInfo.first_name)) {
        error.first_name = "First name cannot be empty";
      }
    }
    if (Utils.isEmpty(employeeInfo.name_format)) {
      error.name_format = "Name format cannot be empty";
    }
    if (Utils.isEmpty(employeeInfo.pay_frequency)) {
      error.pay_frequency = "Pay frequency cannot be empty";
    }
    if (Utils.isEmpty(employeeInfo.date_of_appointment)) {
      error.date_of_appointment = "Date of appointment cannot be empty";
    }
    if (Utils.isEmpty(legalStatusInfo.legal_status)) {
      error.legal_status = "Legal status cannot be empty";
    }
    
    if(legalStatusInfo.legal_status == 2 ||
      legalStatusInfo.legal_status == 3 ||
      legalStatusInfo.legal_status == 4 ||
      legalStatusInfo.legal_status == 6 || 
      legalStatusInfo.legal_status === "7")
    {
      let nationalityValid =nationalityValidate(legalStatusInfo?.nationality);
      console.log("COMING HERE",nationalityValid,legalStatusInfo.legal_status,error)
    if (nationalityValid) {
      error.nationality = nationalityValid;
    }
    }
   if(legalStatusInfo.legal_status == 5 )
   {
    let malaysian_icValid= validateMalaysianIC(legalStatusInfo?.malaysian_ic);
    if(malaysian_icValid)
    {
      error.malaysian_ic =malaysian_icValid;
    }
   }
  
    
    
    if (Utils.isEmpty(employeeInfo.gender)) {
      error.gender = "Gender cannot be empty";
    }
    if (Utils.isEmpty(employeeInfo.race)) {
      error.race = "Race cannot be empty";
    }
    if (Utils.isEmpty(employeeInfo.religion)) {
      error.religion = "Religion cannot be empty";
    }
    if (Utils.isEmpty(addressInfo.address_type)) {
      error.address_type = "Address type cannot be empty";
    }
    if (Utils.isEmpty(employeeInfo.job_title)) {
      error.job_title = "Job cannot be empty";
    }
    if (manualEmpNumber && Utils.isEmpty(employeeInfo.emp_number)) {
      error.employee_code = "Employee number cannot be empty";
    }
    if (Utils.isEmpty(employeeInfo.email)) {
      error.email = "Email cannot be empty";
    }
    let phoneValid = phoneValidate(employeeInfo.phone);
    if (phoneValid) {
      error.phone = phoneValid;
    }

    let emailValid = validateEmail(employeeInfo.email);
    if(emailValid) {
      error.email = emailValid;
    }

    if (Utils.isEmpty(employeeInfo.payment_method)) {
      error.payment_method = "Payment method cannot be empty";
    }
    if (
      legalStatusInfo.legal_status === 1 ||
      legalStatusInfo.legal_status === 2
    ) {
      let nricValid = validateNRIC(legalStatusInfo.nric);
      if (nricValid) {
        error.nric = nricValid;
      }
    }

    if (legalStatusInfo.legal_status === 2) {
      if (Utils.isEmpty(legalStatusInfo.start_permanent_date)) {
        error.start_permanent_date = "Start permanent date cannot be empty";
      }
    }
    if (
      legalStatusInfo.legal_status === 3 ||
      legalStatusInfo.legal_status === 4
    ) {
      let finValid = validateFIN(legalStatusInfo.fin);
      if (finValid) {
        error.fin = finValid;
      }
    }
if(legalStatusInfo.legal_status == 1 ||
  legalStatusInfo.legal_status == 2)
{
  if(Utils.isEmpty(legalStatusInfo.nric))
  {
    error.nric="NRIC cannot be empty";
  }
}
if(legalStatusInfo.legal_status == 6 ||
  legalStatusInfo.legal_status == 7)
{
  if(Utils.isEmpty(legalStatusInfo.passport))
  {
    error.passport="passport details cannot be empty";
  }
}
    if (legalStatusInfo.legal_status === 6) {
      if (Utils.isEmpty(legalStatusInfo.passport)) {
        error.passport = "Passport cannot be empty";
      }
    }
    if (
      addressInfo.address_type === 1 ||
      addressInfo.address_type === 2 ||
      addressInfo.address_type === 3
    ) {
      if (Utils.isEmpty(addressInfo.postal_code)) {
        error.postal_code = "Postal code cannot be empty";
      }
    }

    if(legalStatusInfo.legal_status == 3 ||
      legalStatusInfo.legal_status == 4)
    {
      if(Utils.isEmpty(legalStatusInfo.fin))
      {
        error.fin="FIN cannot be empty";
      }
    }

    if (addressInfo.address_type === 1) {
      if (Utils.isEmpty(addressInfo.block_no)) {
        error.block_no = "Block no cannot be empty";
      }
    }

    if (employeeInfo.payment_method === 5) {
      if (Utils.isEmpty(bankInfo?.bank_name)) {
        error.bank_name = "bank name cannot be empty";
      }
      if (Utils.isEmpty(bankInfo?.bank_account_number)) {
        error.bank_account_number = "bank account number cannot be empty";
      }
      if (Utils.isEmpty(bankInfo?.bank_branch_code)) {
        error.bank_branch_code = "bank branch code cannot be empty";
      }
    }
  } 
  catch (error)
  {
  console.error(error);
  }

  if (Object.keys(error).length > 0) {
    window.scrollTo(0, 0);
    console.log("error at end",error)
    Object.entries(error).forEach((item) => {
      updatedErrorByKey(item[0], item[1]);
    });
    return true;
  }
  return false;
};

export const Validate = {
  addEmployeeDataValidate,
  validateNRIC,
  validateFIN,
  phoneValidate,
  validateMalaysianIC,
  nationalityValidate
};
