import { NavLink, Outlet, useLocation } from "react-router-dom";

import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdOutlineDashboard,
  MdOutlineCorporateFare,
  MdSupervisedUserCircle,
} from "react-icons/md/";
import { BsCalculator } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import {
  FaTelegramPlane,
  FaUsers,
  FaDollarSign,
  FaCheck,
  FaFileExcel,
  FaMobileAlt,
  FaRegCalendarCheck,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { IconContext } from "react-icons";
import { memo, useContext, useEffect, useState } from "react";
import { ManageCPFCal } from "../../_service";
import { updateAppSettingId, updateCpfCalCatogary } from "../../_features";
import { useAppDispatch, useAppSelector } from "../../_app";
import { DashboardContainer, LeftSection, RightSection } from "./Styles";
import { AiFillBook, AiFillClockCircle } from "react-icons/ai";

const activeStyle = {
  color: "#28a745",
  fontWeight: "bold",
};

const routeToSection = {
  "manage-company": "company",
  "employee-list": "employee",
  payment_runs: "payrun",
  monthly: "filing",
  iras: "filing",
  tms: "tms",
  "manage-users": "users",
  "add-employee": "employeee",
  "self-service": "employee",
  approve: "approve",
  manual: "manual",
  "employee-payroll": "employee",
  reports: "manage-report",
  payslips: "payslips",
  requests: "requests",
  payroll: "payroll",
  "annual-bonus": "annual-bonus",
  "annual-increment": "annual-increment",
} as { [key: string]: string };

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const cpfCatogary = useAppSelector((state) => state.dashboard.cpfCalCatogry);
  const menu = useAppSelector((state) => state.user.menu);
  const groups = useAppSelector((state) => state.user.groups);
  const permission = useAppSelector((state) => state.user.permission);

  const [cpfOpen, setCpfOpen] = useState<boolean>(false);
  const [settingOpen, setSettingOpen] = useState<boolean>(false);
  const [payslipOpen, setPaySlipOpen] = useState<boolean>(false);
  const [templateOpen, setTemplateOpen] = useState<boolean>(false);
  const [usersOpen, setUsersOpen] = useState<boolean>(false);
  const [tellbossOpen, setTellbossOpen] = useState<boolean>(false);

  const [cpfSelect, setCpfSelect] = useState<boolean>(false);
  const [settingSelect, setSettingSelect] = useState<boolean>(false);
  const [templateSelect, setTemplateSelect] = useState<boolean>(false);
  const [usersSelect, setUsersSelect] = useState<boolean>(false);
  const [payslipSelect, setPayslipSelect] = useState<boolean>(false);
  const [tellbossSelect, setTellbossSelect] = useState<boolean>(false);

  const [selectedSection, setSelectedSection] = useState("");
  const location = useLocation();

  const handleLinkOpen = (str: string) => {
    if (str === "cpf") {
      setCpfOpen(true);
      setCpfSelect(true);
      setSettingSelect(false);
      setPayslipSelect(false);
      setTemplateSelect(false);
      setUsersSelect(false);
      setTellbossSelect(false);
    }
    if (str === "setting") {
      setSettingOpen(true);
      setCpfSelect(false);
      setSettingSelect(true);
      setPayslipSelect(false);
      setTemplateSelect(false);
      setUsersSelect(false);
      setTellbossSelect(false);
    }
    if (str === "payslip") {
      setCpfSelect(false);
      setSettingSelect(false);
      setPaySlipOpen(true);
      setPayslipSelect(true);
      setTemplateSelect(false);
      setUsersSelect(false);
      setTellbossSelect(false);
    }
    if (str === "template") {
      setCpfSelect(false);
      setSettingSelect(false);
      setPayslipSelect(false);
      setTemplateSelect(true);
      setTemplateOpen(true);
      setUsersSelect(false);
      setTellbossSelect(false);
    }
    if (str === "users") {
      setCpfSelect(false);
      setSettingSelect(false);
      setPayslipSelect(false);
      setTemplateSelect(false);
      setUsersSelect(true);
      setUsersOpen(true);
      setTellbossSelect(false);
    }
    // if (str === "tellboss") {
    //   setCpfSelect(false);
    //   setSettingSelect(false);
    //   setPayslipSelect(false);
    //   setTemplateSelect(false);
    //   setUsersSelect(false);
    //   setTellbossSelect(true);
    //   setTellbossOpen(true);
    // }
    if (str === "payroll") {
      setCpfSelect(false);
      setSettingSelect(false);
      setPayslipSelect(false);
      setTemplateSelect(false);
      setUsersSelect(false);
      setTellbossSelect(true);
      setTellbossOpen(true);
    }
  };

  const getCPFCalCategory = async () => {
    try {
      const res = await ManageCPFCal._getCpfCalCategory("");
      dispatch(updateCpfCalCatogary(res.data));
    } catch (e) {
      console.error(e);
    }
  };
  const handleAppSettingClick = (e: any) => {
    e.preventDefault();
    // updateAppSettingId
    navigate("app-settings/employer-details");
    dispatch(updateAppSettingId(companyId));
  };

  const handleClick = (to?: string) => {
    if (to) {
      navigate(to);
    }
    setSettingSelect(false);
    setCpfSelect(false);
    setPayslipSelect(false);
    setTemplateSelect(false);
    setUsersSelect(false);
    setTemplateSelect(false);
    setTellbossSelect(false);
  };

  useEffect(() => {
    if (!cpfCatogary) {
      getCPFCalCategory();
    }
  }, []);

  useEffect(() => {
    const pathToSearch = location.pathname.split("/").pop();
    if (pathToSearch) {
      setSelectedSection(routeToSection[pathToSearch]);
    }
  }, [location]);

  return (
    <DashboardContainer>
      <LeftSection>
        <div
          className="link-menu-item"
          style={{
            color: "#fff9",
          }}
        >
          <p
            style={{
              borderBottom: "1px solid #fff9",
              width: "90%",
              paddingBottom: 5,
            }}
          >
            <strong>Administration</strong>
          </p>
        </div>
        {menu?.includes("dashboard") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("request/data-entry")}
            style={{
              color: selectedSection === "request" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider
              value={{
                color: selectedSection === "request" ? "#28a745" : "#fff9",
                size: "20px",
              }}
            >
              <MdOutlineDashboard />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "request" ? "#28a745" : "#fff9",
              }}
            >
              Dashboard
            </p>
          </div>
        )}
        {menu?.includes("manage_company") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("manage-company")}
            style={{
              color: selectedSection === "company" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <MdOutlineCorporateFare />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "company" ? "#28a745" : "#fff9",
              }}
            >
              Manage Companies
            </p>
          </div>
        )}
        {menu?.includes("manage_employee") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("employee-payroll")}
            style={{
              color: selectedSection === "employee" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider
              value={{
                color: selectedSection === "employee" ? "#28a745" : "#fff9",
                size: "20px",
              }}
            >
              <FaUsers />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "employee" ? "#28a745" : "#fff9",
              }}
            >
              Manage Employees
            </p>
          </div>
        )}

        {menu?.includes("pay_run") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("payment_runs")}
            style={{
              color: selectedSection === "payrun" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <FaDollarSign />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "payrun" ? "#28a745" : "#fff9",
              }}
            >
              Pay Runs
            </p>
          </div>
        )}
        {menu?.includes("payroll") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("tellboss/payroll")}
            style={{
              color: selectedSection === "payroll" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <FaMobileAlt />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "payroll" ? "#28a745" : "#fff9",
              }}
            >
              Payroll
            </p>
          </div>
          // <div className={`sub-menu-list`}>
          //   <div
          //     className="link-menu-item"
          //     onClick={() => setTellbossOpen((p) => !p)}
          //   >
          //     <IconContext.Provider
          //       value={{
          //         color: tellbossSelect ? "#28a745" : "#fff9",
          //         size: "20px",
          //       }}
          //     >
          //       <FaMobileAlt />
          //     </IconContext.Provider>
          //     <p
          //       className="link"
          //       style={{ color: tellbossSelect ? "#28a745" : "#fff9" }}
          //     >
          //       Tellboss
          //     </p>
          //     {!tellbossOpen ? (
          //       <IconContext.Provider value={{ color: "#fff9", size: "20px" }}>
          //         <MdArrowDropDown />
          //       </IconContext.Provider>
          //     ) : (
          //       <IconContext.Provider
          //         value={{
          //           color: tellbossSelect ? "#28a745" : "#fff9",
          //           size: "20px",
          //         }}
          //       >
          //         <MdArrowDropUp />
          //       </IconContext.Provider>
          //     )}
          //   </div>
          //   <div className={`sub-menu-list ${tellbossOpen ? "show" : "hide"}`}>
          //     <NavLink
          //       style={({ isActive }) => {
          //         if (isActive) {
          //           handleLinkOpen("tellboss");
          //           return activeStyle;
          //         }
          //       }}
          //       className="sublink"
          //       to="tellboss/payroll"
          //     >
          //       Payroll
          //     </NavLink>
          //   </div>
          // </div>
        )}
        {menu?.includes("filling") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("filing/monthly")}
            style={{
              color: selectedSection === "filing" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <FaTelegramPlane />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "filing" ? "#28a745" : "#fff9",
              }}
            >
              Filing
            </p>
          </div>
        )}

        {menu?.includes("report") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("reports")}
            style={{
              color: selectedSection === "manage-report" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <MdOutlineCorporateFare />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color:
                  selectedSection === "manage-report" ? "#28a745" : "#fff9",
              }}
            >
              Reports
            </p>
          </div>
        )}

        {menu?.includes("report_history") && (
          <div className={`sub-menu-list`}>
            <div
              className="link-menu-item"
              onClick={() => setPaySlipOpen((p) => !p)}
            >
              <IconContext.Provider
                value={{
                  color: payslipSelect ? "#28a745" : "#fff9",
                  size: "20px",
                }}
              >
                <AiFillBook />
              </IconContext.Provider>
              <p
                className="link"
                style={{ color: payslipSelect ? "#28a745" : "#fff9" }}
              >
                Reports History
              </p>
              {!payslipOpen ? (
                <IconContext.Provider value={{ color: "#fff9", size: "20px" }}>
                  <MdArrowDropDown />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider
                  value={{
                    color: payslipSelect ? "#28a745" : "#fff9",
                    size: "20px",
                  }}
                >
                  <MdArrowDropUp />
                </IconContext.Provider>
              )}
            </div>
            <div className={`sub-menu-list ${payslipOpen ? "show" : "hide"}`}>
              <NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("payslip");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="payslip/current"
              >
                Current Year
              </NavLink>

              {<NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("payslip");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="payslip/history"
              >
                History
              </NavLink>}
            </div>
          </div>
        )}

        {menu?.includes("annual_increment") &&
          <div
            className="link-menu-item"
            onClick={() => handleClick("annual-increment")}
            style={{
              color: selectedSection === "annual-increment" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <MdOutlineCorporateFare />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color:
                  selectedSection === "annual-increment" ? "#28a745" : "#fff9",
              }}
            >
              Annual Increment
            </p>
          </div>
        }
        {menu?.includes("annual_bonus") &&
          <div
            className="link-menu-item"
            onClick={() => handleClick("annual-bonus")}
            style={{
              color: selectedSection === "annual-bonus" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <MdOutlineCorporateFare />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color:
                  selectedSection === "annual-bonus" ? "#28a745" : "#fff9",
              }}
            >
              Annual Bonus
            </p>
          </div>
        }
        {menu?.includes("manual_ir8a") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("filing/manual")}
            style={{
              color: selectedSection === "manual" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <FaFileExcel />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "manual" ? "#28a745" : "#fff9",
              }}
            >
              Manual IR8A
            </p>
          </div>
        )}

        {menu?.includes("manage_tms") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("tms")}
            style={{ color: selectedSection === "tms" ? "#28a745" : "#fff9" }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <AiFillClockCircle />
            </IconContext.Provider>
            <p
              className="link"
              style={{ color: selectedSection === "tms" ? "#28a745" : "#fff9" }}
            >
              Manage TMS
            </p>
          </div>
        )}

        {(menu?.includes("manage_user") || menu?.includes("manage_group") || menu?.includes("manage_group_permission") ) && (
          <div className={`sub-menu-list `}>
            <div
              className="link-menu-item"
              onClick={() => setUsersOpen((p) => !p)}
            >
              <IconContext.Provider
                value={{
                  color: usersSelect ? "#28a745" : "#fff9",
                  size: "20px",
                }}
              >
                <MdSupervisedUserCircle />
              </IconContext.Provider>
              <p
                className="link"
                style={{ color: usersSelect ? "#28a745" : "#fff9" }}
              >
                Manage Users
              </p>
              {!usersOpen ? (
                <IconContext.Provider value={{ color: "#fff9", size: "20px" }}>
                  <MdArrowDropDown />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider
                  value={{
                    color: usersSelect ? "#28a745" : "#fff9",
                    size: "20px",
                  }}
                >
                  <MdArrowDropUp />
                </IconContext.Provider>
              )}
            </div>
            <div className={`sub-menu-list ${usersOpen ? "show" : "hide"}`}>
              {menu?.includes("manage_user") && <NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("users");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="users"
              >
                Users
              </NavLink>}

              {menu?.includes("manage_group") && <NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("users");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="manage-users/groups"
              >
                Manage Group
              </NavLink>}

              {menu?.includes("manage_group_permission") && permission === "full" && (
                <NavLink
                  style={({ isActive }) => {
                    if (isActive) {
                      handleLinkOpen("users");
                      return activeStyle;
                    }
                  }}
                  className="sublink"
                  to="manage-users/edit-groups"
                >
                  Manage Groups Permissions
                </NavLink>
              )}
            </div>
          </div>
        )}
        {/* {menu?.includes("cpf_calculator") && (
          <div className={`sub-menu-list `}>
            <div
              className="link-menu-item"
              onClick={() => setCpfOpen((p) => !p)}
            >
              <IconContext.Provider
                value={{ color: cpfSelect ? "#28a745" : "#fff9", size: "20px" }}
              >
                <BsCalculator />
              </IconContext.Provider>
              <p
                className="link"
                style={{ color: cpfSelect ? "#28a745" : "#fff9" }}
              >
                CPF Calculator
              </p>
              {!cpfOpen ? (
                <IconContext.Provider value={{ color: "#fff9", size: "20px" }}>
                  <MdArrowDropDown />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider
                  value={{
                    color: cpfSelect ? "#28a745" : "#fff9",
                    size: "20px",
                  }}
                >
                  <MdArrowDropUp />
                </IconContext.Provider>
              )}
            </div>
            <div>
              {(cpfCatogary || [])?.map((item: any) => (
                <div key={item.id} className={`${cpfOpen ? "show" : "hide"}`}>
                  <NavLink
                    style={({ isActive }) => {
                      if (isActive) {
                        handleLinkOpen("cpf");
                        return activeStyle;
                      }
                    }}
                    className="sublink"
                    to={`cpf-cal/${item.code}`}
                    key={item.id}
                  >
                    {item.name}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        )} */}
        {menu?.includes("manage_templates") && (
          <div className={`sub-menu-list`}>
            <div
              className="link-menu-item"
              onClick={() => setTemplateOpen((p) => !p)}
            >
              <IconContext.Provider
                value={{
                  color: templateSelect ? "#28a745" : "#fff9",
                  size: "20px",
                }}
              >
                <FiSettings />
              </IconContext.Provider>
              <p
                className="link"
                style={{ color: templateSelect ? "#28a745" : "#fff9" }}
              >
                Manage Templates
              </p>
              {!templateOpen ? (
                <IconContext.Provider value={{ color: "#fff9", size: "20px" }}>
                  <MdArrowDropDown />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider
                  value={{
                    color: templateSelect ? "#28a745" : "#fff9",
                    size: "20px",
                  }}
                >
                  <MdArrowDropUp />
                </IconContext.Provider>
              )}
            </div>
            <div className={`sub-menu-list ${templateOpen ? "show" : "hide"}`}>
              <NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("template");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="manage-template/hr"
              >
                HR Templates
              </NavLink>
            </div>
          </div>
        )}

        {(menu?.includes("app_setting") || menu?.includes("manage_cpf_limit") || menu?.includes("manage_cpf_table") || menu?.includes("manage_cpf_sdl") || menu?.includes("manage_deduction")) && (
          <div className={`sub-menu-list`}>
            <div
              className="link-menu-item"
              onClick={() => setSettingOpen((p) => !p)}
            >
              <IconContext.Provider
                value={{
                  color: settingSelect ? "#28a745" : "#fff9",
                  size: "20px",
                }}
              >
                <FiSettings />
              </IconContext.Provider>
              <p
                className="link"
                style={{ color: settingSelect ? "#28a745" : "#fff9" }}
              >
                Settings
              </p>
              {!settingOpen ? (
                <IconContext.Provider value={{ color: "#fff9", size: "20px" }}>
                  <MdArrowDropDown />
                </IconContext.Provider>
              ) : (
                <IconContext.Provider
                  value={{
                    color: settingSelect ? "#28a745" : "#fff9",
                    size: "20px",
                  }}
                >
                  <MdArrowDropUp />
                </IconContext.Provider>
              )}
            </div>
            <div className={`sub-menu-list ${settingOpen ? "show" : "hide"}`}>
              {menu?.includes("manage_cpf_table") && <NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("setting");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="manage-cpf-table"
              >
                Manage CPF Table
              </NavLink>}

              {menu?.includes("manage_cpf_limit") && <NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("setting");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="manage-cpf-limit"
              >
                Manage CPF Limit
              </NavLink>}

              {menu?.includes("manage_deduction") && <NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("setting");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="manage-deduction"
              >
                Manage Deduction
              </NavLink>}

              {menu?.includes("manage_sdl") && <NavLink
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("setting");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="manage-sdf"
              >
                Manage SDL
              </NavLink>}

              {menu?.includes("app_setting") && <NavLink
                onClick={handleAppSettingClick}
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("setting");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="app-settings/employer-details"
              >
                Manage Settings
              </NavLink>}

              {menu?.includes("manage_report") && <NavLink
                onClick={()=>handleClick("manage-report")}
                style={({ isActive }) => {
                  if (isActive) {
                    handleLinkOpen("manage_report");
                    return activeStyle;
                  }
                }}
                className="sublink"
                to="manage-report"
              >
                Manage Report
              </NavLink>}
            </div>
          </div>
        )}
        
        {menu?.includes("leave_approval") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("approve")}
            style={{
              color: selectedSection === "approve" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <FaCheck />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "approve" ? "#28a745" : "#fff9",
              }}
            >
              Leave Approval
            </p>
          </div>
        )}

        {(menu?.includes("payslip") || menu?.includes("request") ) && <div
          className="link-menu-item"
          style={{
            color: "#fff9",
          }}
        >
          <p
            style={{
              borderBottom: "1px solid #fff9",
              width: "90%",
              paddingBottom: 5,
            }}
          >
            <strong>Employee</strong>
          </p>
        </div>}

        {menu?.includes("payslip") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("payslips")}
            style={{
              color: selectedSection === "payslips" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <FaDollarSign />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "payslips" ? "#28a745" : "#fff9",
              }}
            >
              Payslips
            </p>
          </div>
        )}
        {menu?.includes("request") && (
          <div
            className="link-menu-item"
            onClick={() => handleClick("requests")}
            style={{
              color: selectedSection === "requests" ? "#28a745" : "#fff9",
            }}
          >
            <IconContext.Provider value={{ size: "20px" }}>
              <FaRegCalendarCheck />
            </IconContext.Provider>
            <p
              className="link"
              style={{
                color: selectedSection === "requests" ? "#28a745" : "#fff9",
              }}
            >
              Requests
            </p>
          </div>
        )}
      </LeftSection>
      <RightSection>
        <Outlet />
      </RightSection>
    </DashboardContainer>
  );
};

export default memo(Dashboard);
