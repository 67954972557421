import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  CompanyService,
  EmployeeService,
  HelperService,
  PayrollService,
} from "../../../_service";
import validator from "validator";
import { Notify, Utils, Validate } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../_app";
import {
  ConfirmationModalInterface,
  PayFrequencyInterface,
} from "../../../_types";
import {
  ConfirmationModal,
  FormErrorMessage,
  Loader,
} from "../../../components";
import {
  CustomDateField,
  CustomGroupSelectField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  CustomeCkeckboxField,
} from "../../../components/customFormField";
import { CustomButton } from "../../../components/customButton";
import { updateCurrentEmployee } from "../../../_features/employee";

const EditEmployeeInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const employee = useAppSelector((state) => state.employee);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const id = useAppSelector((state) => state.employee.employee_info.id);

  const user = useAppSelector((state) => state.user);

  const [response, setResponse] = useState<any>();
  const [is_contain_finlaized_payslip, setIs_contain_finlaized_payslip] =
    useState(false);

  const [startDate, setStartDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payFrequencyVal, setPayFrequencyVal] = useState<string>("");
  const [is_deduction_except, setIs_deduction_except] = useState(false);
  const [legalName, setLegalName] = useState("");
  const [legalStatus, setLegalStatus] = useState([]);
  const [isShgDedcution, setIsShgDeduction] = useState(false);

  const [legalStatusSelected, setLegalStatusSelected] = useState<any>("");
  const [addressTypeSelected, setAddressTypeSelected] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [raceList, setRaceList] = useState([]);
  const [raceData, setRaceData] = useState([]);
  const [relisionList, setRelisionList] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [payMethods, setPayMethods] = useState([]);
  const [startPermanentResident, setStartPermanentResident] = useState<any>(
    new Date()
  );

  const [nric, setNric] = useState("");
  const [nationality, setNationality] = useState("");
  const [fin, setFin] = useState("");
  const [malaysianIC, setMalaysianIC] = useState("");
  const [passport, setPassport] = useState("");

  const [blockNo, setBlockNo] = useState("");
  const [street, setStreet] = useState("");
  const [level, setLevel] = useState("");
  const [unit, setUnit] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [sdl, setSdl] = useState(false);
  const [cpf, setCpf] = useState(false);
  const [dual_shg_deduction,set_Dual_shg_deduction]=useState(false);

  const [bankList, setBankList] = useState([]);
  const [addressType, setAddressType] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [nameFormat, setNameFormat] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [religion, setReligion] = useState("");
  const [phone, setphone] = useState("");

  const [bankValue, setBankValue] = useState("");
  const [empNumber, setEmpNumber] = useState("");
  const [error, setError] = useState(false);
  const [errorCollection, setErrorCollection] = useState<{
    [key: string]: any;
  }>({});
  const [payFrequency, setPayFrequency] = useState<
    PayFrequencyInterface[] | null
  >(null);

  const handleNavigateBack = () => {
    navigate(-1);
  };
  const handleUpdateEmployee = async () => {
    setLoading(true);
    try {
      const employee_info = {
        first_name: firstName.toUpperCase(),
        ...(nameFormat !== "Single Name"
          ? { last_name: lastName.toUpperCase() }
          : {}),
        email: email,
        pay_frequency: payFrequencyVal,
        name_format: nameFormat ? nameFormat : "Last Name First Name",
        job_title: jobTitle.toUpperCase(),
        date_of_birth: startDate,
        date_of_appointment: startDate1,
        gender: gender,
        company: user.defaultCompnayId,
        is_sdl: sdl,
        is_cpf: cpf,
        is_dual_shg_deduction:dual_shg_deduction,
        
        race: race,
        payment_method: paymentMethod,
        religion: religion,
        ...(empNumber && { emp_number: `${empNumber}` }),
        is_deduction_except,
        phone,
        is_shg_deduction: isShgDedcution,
      };
      const bank_info = {
        bank_name: bankValue.toUpperCase(),
        bank_account_number: alphaNumericInput.toUpperCase() || null,
        bank_branch_code: branchCode.toUpperCase() || null,
      };
      const address_info = {
        ...(addressTypeSelected == "1"
          ? {
              block_no: blockNo.toUpperCase() || null,
              street_name: street.toUpperCase() || null,
              level_no: level || null,
              unit_no: unit || null,
            }
          : {}),
        ...(addressTypeSelected == "2" || addressTypeSelected == "3"
          ? {
              address_line_1: address1.toUpperCase() || null,
              address_line_2: address2.toUpperCase() || null,
              address_line_3: address3.toUpperCase() || null,
            }
          : {}),
        ...(addressTypeSelected == "1" ||
        addressTypeSelected == "2" ||
        addressTypeSelected == "3"
          ? {
              postal_code: postalCode || null,
            }
          : {}),
        ...(addressTypeSelected == "2"
          ? {
              country: countryCode || null,
            }
          : {}),
        address_type: addressTypeSelected,
      };
      const legal_status_info = {
        ...(legalStatusSelected == "1" || legalStatusSelected == "2"
          ? { nric: nric || null }
          : {}),
        ...(legalStatusSelected == "6" || legalStatusSelected == "7"
          ? { passport: passport || null }
          : {}),
        ...(legalStatusSelected == "5"
          ? { malaysian_ic: malaysianIC || null }
          : {}),
        ...(legalStatusSelected == "3" || legalStatusSelected == "4"
          ? { fin: fin || null }
          : {}),
        ...(legalStatusSelected == "2"
          ? {
              start_permanent_date: startPermanentResident || null,
            }
          : {}),
        ...(legalStatusSelected == "2" ||
        legalStatusSelected == "3" ||
        legalStatusSelected == "4" ||
        legalStatusSelected == "6" ||
        legalStatusSelected === "7"
          ? { nationality: nationality || null }
          : {}),
        legal_status: legalStatusSelected,
      };
      const err = Validate.addEmployeeDataValidate(
        employee_info,
        legal_status_info,
        address_info,
        bank_info,
        updatedErrorByKey
      );
      if (err) {
        window.scrollTo(0, 0);
        return;
      }
      setLoading(true);

      if(!(religion == "4" && race == "1"||"3"||"4"))
        {
          employee_info.is_dual_shg_deduction = false;
        }
        
      const res = await PayrollService._updateEmployeeData({
        id,
        data: {
          employee_info,
          ...(paymentMethod == "5"
            ? {
                bank_info,
              }
            : {}),
          address_info,
          legal_status_info,
        },
      });
      
      if (res?.status == 200) {
        Notify("Employee Updated Successfully", 1);
        getEmployee();
        setErrorCollection({});
        handleNavigateBack();
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e?.response?.status !== 401 && e?.response?.status !== 500) {
        if (Object.keys(e?.response?.data || {})) {
          const err: { [key: string]: string; value: string } | any = {};
          Object.entries(e?.response?.data || {}).forEach((item: any) => {
            err[item[0]] = item[1][0];
          });
          setErrorCollection(err);
          window.scrollTo(0, 0);
        } else {
          console.error(e);
        }
      }
    }
  };

  useEffect(() => {
    const option = legalStatusSelected;
    if (option == "1" || option == "2") {
      setIs_deduction_except(false);
      setRaceList(() => raceData);
    } else if (option !== "") {
      console.log("Entered the race block", raceList)
      setRaceList(() => raceList?.filter((i: any) => i.id == 5));
      setRace(() => "5");
      setIs_deduction_except(true);
      setCpf(true);
    }
  }, [legalStatusSelected, raceList?.length]);

  const updatedErrorByKey = (key: string, value: string = "") => {
    if (value === "") {
      let err = errorCollection;
      delete err[`${key}`];
      setErrorCollection(err);
    } else {
      setErrorCollection((prev) => ({ ...prev, [key]: value }));
    }
  };
  const handleLegalNameFormat = (e: any) => {
    setLegalName(e);
  };

  const handleAddressType = (e: any) => {
    setAddressTypeSelected(e);
  };

  const get = async () => {
    setLoading(true)
    console.log("get was called")
    getEmployee();

    const LegalStatus = await HelperService._getLegalStatus();
    const countryList = await HelperService._getCountry();
    const raceList = await HelperService._getRace();
    const relisionList = await HelperService._getReligion();
    const payMethods = await HelperService._getPaymentMethods();
    const bankList = await HelperService._getBankList();
    const addressType = await HelperService._getAddressTypes();

    CompanyService._getPayFrequency(companyId).then((data) => {
      if (data.length > 0) {
        setPayFrequency(data);
      }
    });

    // legal status
    setLegalStatus(() => LegalStatus.data);

    // get country name
    setCountryList(() => countryList.data);

    // get race
    setRaceList(() => raceList.data);
    setRaceData(() => raceList.data);

    //get relision
    setRelisionList(() => relisionList.data);

    //get pay Methods
    setPayMethods(() => payMethods.data);

    //get bank list
    setBankList(() => bankList.data);

    // get address type
    setAddressType(() => addressType.data);
    setLoading(false);
  };

  const getEmployee = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const res = await PayrollService._getEmployeeDataById(id);
      if (res.status === 200) {
        dispatch(
          updateCurrentEmployee({
            currentEmployee: {
              ...employee.currentEmployee,
              first_name: res?.data?.first_name || "",
              last_name: res?.data?.last_name || "",
              id: id,
              name: res?.data?.name || "",
            },
            employee_info: {
              ...employee.employee_info,
              emp_number: res?.data?.emp_number || "",
            },
          })
        );
        setResponse(() => res.data);
        setIs_contain_finlaized_payslip(
          () => res.data?.is_contain_finlaized_payslip || false
        );
        setAddress1(() => res.data?.address_data[0]?.address_line_1 || "");
        setAddress2(() => res?.data?.address_data[0]?.address_line_2 || "");
        setAddress3(() => res?.data?.address_data[0]?.address_line_3 || "");
        setBlockNo(() => res?.data?.address_data[0]?.block_no || "");
        setCountryCode(() => res?.data?.address_data[0]?.country || "");
        setLevel(() => res?.data?.address_data[0]?.level_no || "");
        setPostalCode(() => res?.data?.address_data[0]?.postal_code || "");
        setStreet(() => res?.data?.address_data[0]?.street_name);
        setUnit(() => res?.data?.address_data[0]?.unit_no || "");

        setEmpNumber(() => res?.data?.emp_number || "");

        setFin(() => res?.data?.fin || "");
        setMalaysianIC(() => res?.data?.malaysin_ic || "");
        setNationality(() => res?.data?.nationality || "");
        setNric(() => `${res?.data?.nric}` || "");
        setPassport(() => res?.data?.passport || "");
        setStartPermanentResident(() =>
          res.data?.start_permanent_date ? res.data.start_permanent_date : null
        );

        setPayFrequencyVal(() => res?.data?.pay_frequency || "");
        setNameFormat(() => res?.data?.name_format || "");
        setLegalName(() => res?.data?.name_format || "");
        setFirstName(() => res?.data?.first_name || "");
        setLastName(() => res?.data?.last_name || "");
        setStartDate(() => res?.data?.date_of_birth);
        setStartDate1(() => res?.data?.date_of_appointment);

        setLegalStatusSelected(() => `${res.data?.legal_status}` || "");
        setIsShgDeduction(() => res?.data?.is_shg_deduction);
        setGender(() => res?.data?.gender || "");
        setSdl(() => res?.data?.is_sdl);
        setCpf(() => res?.data?.is_cpf);
        set_Dual_shg_deduction(()=>res?.data?.is_dual_shg_deduction);
        setJobTitle(() => res?.data?.job_title || "");
        setRace(() => `${res?.data?.race}` || "");
        setReligion(() => res?.data?.religion || "");
        setAddressTypeSelected(
          () => `${res.data?.address_data[0]?.address_type || ""}`
        );
        setEmail(() => res?.data?.email || "");
        setphone(() => res?.data?.phone || "");

        setBankValue(() => res?.data?.bank_name || "");
        setAlphaNumericInput(() => res?.data?.bank_account_number || "");
        setBranchCode(() => res?.data?.bank_branch_code || "");
        setPaymentMethod(() => res?.data?.payment_method || "");
      }
    } catch (e: any) {
      if (e.response.status == 401) {
        // disaptch(resetUser());
      }
    }
  };
  useEffect(() => {
    get();
  }, []);

  const validateEmail = (email: any) => {
    if(!email){
      setEmailError("Email field cannot be empty!");
      updatedErrorByKey("email", "Email field cannot be empty!");
    } else if (!validator.isEmail(email)) {
      setEmailError("Enter a valid Email!");
      updatedErrorByKey("email", "Enter a valid email!");
    } else {
      setEmailError("");
      updatedErrorByKey("email");
    }
  };
  const validatePhone = (e: string) => {
    if (e.length <= 8) {
      setphone(e);
    }
  };
  const handlePhoneValidation = () => {
    let phoneValid = Validate.phoneValidate(phone);
    if (phoneValid) {
      updatedErrorByKey("phone", phoneValid);
    } else {
      updatedErrorByKey("phone");
    }
  };
  const [alphaNumericInput, setAlphaNumericInput] = useState("");
  const [accountNoError, setAccountNoError] = useState(false);
  const checkAccountNoAlphanumeric = (value: any) => {
    var regEx = /^[0-9a-zA-Z]+$/;
    if (value.match(regEx)) {
      setAccountNoError(true);
    } else {
      setAccountNoError(false);
    }
  };

  const [branchCode, setBranchCode] = useState("");
  const [branchCodeError, setBranchCodeError] = useState(false);
  const checBranchCodeAlphanumeric = (value: any) => {
    var regEx = /^[0-9a-zA-Z]+$/;
    if (value.match(regEx)) {
      setBranchCodeError(true);
    } else {
      setBranchCodeError(false);
    }
  };

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState(false);
  const checkPostalCodeNoAlphanumeric = (value: any) => {
    var regEx = /^[0-9a-zA-Z]+$/;
    if (value.match(regEx)) {
      setPostalCodeError(true);
    } else {
      setPostalCodeError(false);
    }
  };
  const [confirmationModalContent, setConfirmationModalContent] =
    useState<null | ConfirmationModalInterface>(null);
  const handleNRICValidate = async () => {
    try {
      if (nric !== response?.nric) {
        const res = await EmployeeService._validateEmployeeData(`nric=${nric}`);
        if (res.status == 200) {
          if (res?.data?.id) {
            setConfirmationModalContent({
              content: `This NRIC already exists with an Employee.`,
              cancelText: "Cancel",
              resetContent: () => {
                setConfirmationModalContent(null);
                setNric(() => response?.nric || "");
              },
            });
          }
        }
      }
    } catch (e: any) {
      console.error(e.response.data.is_valid, "error");
      let nricValid = Validate.validateNRIC(nric);
      if (nricValid) {
        updatedErrorByKey("nric", nricValid);
      } else {
        updatedErrorByKey("nric", "NRIC is not valid");
      }
      if (e.response.status == 401) {
        // disaptch(resetUser());
      }
    }
  };
  const handleFINValidate = async () => {
    try {
      if (fin !== response?.fin) {
        const res = await EmployeeService._validateEmployeeData(`fin=${fin}`);
        if (res.status == 200) {
          if (res.data.fin.exists == true) {
            if (res?.data?.id) {
              setConfirmationModalContent({
                content: `This FIN number already exists with an Employee.`,
                cancelText: "Cancel",
                resetContent: () => {
                  setConfirmationModalContent(null);
                  setFin(() => response?.fin || "");
                },
              });
            }
          }
        }
      }
    } catch (e: any) {
      let finValid = Validate.validateFIN(fin);
      if (finValid) {
        updatedErrorByKey("fin", finValid);
      } else {
        updatedErrorByKey("fin", "Fin is not valid");
      }
      if (e.response.status == 401) {
        // disaptch(resetUser());
      }
    }
  };
  const handleEmailValidate = async () => {
    const isValid = Utils.validateEmail(email);
    if (isValid && email !== response?.email) {
      try {
        const res = await EmployeeService._validateEmployeeData(
          `email=${email}`
        );
        if (res.status == 200) {
          if (res?.data?.id) {
            if (res?.data?.id) {
              setConfirmationModalContent({
                content: `This Email address already exists with an Employee.`,
                cancelText: "Cancel",
                resetContent: () => {
                  setConfirmationModalContent(null);
                  setEmail(() => response?.email || "");
                },
              });
            }
          }
        }
      } catch (e: any) {
        if (e.response.status == 400) {
          console.error(e.response);
        }
        if (e.response.status == 401) {
          // disaptch(resetUser());
        }
      }
    }
  };

  console.log("here we are", {religion, race});
  const deduction= [
    {id:"1", value: 'CDAC'},
   { id:"3", value:'SINDA'},
   {id: "4", value:'ECF'}
  ];

  return (
    <div style={{ position: "relative" }}>
      {loading && <Loader />}
      <div>
        {confirmationModalContent && (
          <ConfirmationModal
            confirmationModalContent={confirmationModalContent}
          />
        )}
        <div className="new-employee">
          <h4>Basic Details</h4>
        </div>
        <FormErrorMessage name="employee" errorCollection={errorCollection} />

        <CustomTextField
          title="Job Title"
          value={jobTitle}
          onChangeText={(text) => {
            setJobTitle(text);
          }}
          error={
            Object.keys(errorCollection)?.includes("job_title") &&
            errorCollection.job_title
          }
          onFocus={() => updatedErrorByKey("job_title")}
          require={true}
        />
        
        <CustomSelectField
          title="Pay Frequency"
          value={payFrequencyVal}
          onChangeOption={(option) => setPayFrequencyVal(option)}
          option={
            (payFrequency &&
              payFrequency.length > 0 &&
              payFrequency?.map((item) => ({
                key: item.name,
                value: item.id,
              }))) ||
            []
          }
          require={true}
        />
        <CustomSelectField
          title="Legal Name format"
          value={nameFormat}
          onChangeOption={(option) => {
            setNameFormat(option);
            handleLegalNameFormat(option);
            if (legalName == "Single Name") {
              setTimeout(() => {
                setLastName("");
              }, 200);
            }
          }}
          option={[
            { key: "Last Name First Name", value: "Last Name First Name" },
            { key: "First Name Last Name", value: "First Name Last Name" },
            { key: "Single Name", value: "Single Name" },
          ]}
          require={true}
        />
        <CustomTextField
          title="First Name (Given Name)"
          value={firstName}
          onChangeText={setFirstName}
          error={
            Object.keys(errorCollection)?.includes("first_name") &&
            errorCollection.first_name
          }
          onFocus={() => updatedErrorByKey("first_name")}
          require={true}
        />
        {legalName !== "Single Name" && (
          <CustomTextField
            title="Last Name (Family Name)"
            value={lastName}
            onChangeText={setLastName}
            error={
              Object.keys(errorCollection)?.includes("last_name") &&
              errorCollection.last_name
            }
            onFocus={() => updatedErrorByKey("last_name")}
            require={true}
          />
        )}
        {(empNumber || empNumber === "") && (
          <CustomTextField
            title="Employee Number"
            value={empNumber}
            onChangeText={setEmpNumber}
            placeholder="001"
            error={
              Object.keys(errorCollection)?.includes("employee_code") &&
              errorCollection.employee_code
            }
            onFocus={() => updatedErrorByKey("employee_code")}
            require={true}
          />
        )}
        <CustomDateField
          label="Date of Birth"
          date={startDate || null}
          setDate={(date: any) => setStartDate(date)}
          error={
            Object.keys(errorCollection)?.includes("date_of_birth") &&
            errorCollection.date_of_birth
          }
          onFocus={() => updatedErrorByKey("date_of_birth")}
          require={true}
        />
        <CustomDateField
          label="Date of Appointment"
          date={startDate1 || null}
          setDate={(date: any) => setStartDate1(date)}
          error={
            Object.keys(errorCollection)?.includes("date_of_appointment") &&
            errorCollection.date_of_appointment
          }
          onFocus={() => updatedErrorByKey("date_of_appointment")}
          require={true}
        />

        <CustomSelectField
          title="Legal Status"
          placeholder={"Select Legal Status"}
          value={legalStatusSelected || ""}
          onChangeOption={(option) => {
            setLegalStatusSelected(option);
          }}
          option={
            legalStatus?.map((item: any) => ({
              key: item.name,
              value: item.id,
            })) || []
          }
          error={
            Object.keys(errorCollection)?.includes("legal_status") &&
            errorCollection.legal_status
          }
          onFocus={() => updatedErrorByKey("legal_status")}
          require={true}
        />
        {(legalStatusSelected === "3" ||
          legalStatusSelected === "4" ||
          legalStatusSelected === "5" ||
          legalStatusSelected === "6" ||
          legalStatusSelected === "7") && (
          <CustomeCkeckboxField
            title="SHG deduction applicable?"
            value={isShgDedcution}
            onChange={() => setIsShgDeduction((prev) => !prev)}
          />
        )}
        {(legalStatusSelected == "1" || legalStatusSelected == "2") && (
          <CustomTextField
            title="NRIC"
            value={nric || ""}
            onBlur={handleNRICValidate}
            onChangeText={(text) => setNric(text)}
            error={
              (Object.keys(errorCollection)?.includes("non_field_errors") ||
                Object.keys(errorCollection)?.includes("nric")) &&
              (errorCollection.nric || errorCollection.non_field_errors)
            }
            onFocus={() => updatedErrorByKey("non_field_errors")}
            require={true}
          />
        )}
        {(legalStatusSelected == "2" ||
          legalStatusSelected == "3" ||
          legalStatusSelected == "4" ||
          legalStatusSelected == "6" ||
          legalStatusSelected === "7") && (
          <CustomSelectField
            title="Nationality"
            placeholder={"Select Nationality"}
            value={nationality || ""}
            onChangeOption={(option) => {
              setNationality(option);
            }}
            option={
              countryList?.map((item: any) => ({
                key: item.nationality,
                value: item.id,
              })) || []
            }
            error={
              Object.keys(errorCollection)?.includes("nationality") &&
              errorCollection.nationality
            }
            onFocus={() => updatedErrorByKey("nationality")}
            require={true}
          />
        )}
        {legalStatusSelected == "2" && (
          <>
            <CustomDateField
              label="Start of Permanent Status"
              date={startPermanentResident || null}
              setDate={(date: any) => setStartPermanentResident(date)}
              require
              error={
                Object.keys(errorCollection)?.includes(
                  "start_permanent_date"
                ) && errorCollection.start_permanent_date
              }
              onFocus={() => updatedErrorByKey("start_permanent_date")}
            />
          </>
        )}
        {(legalStatusSelected == "3" || legalStatusSelected == "4") && (
          <CustomTextField
            title="FIN"
            value={fin}
            require
            onBlur={handleFINValidate}
            onChangeText={setFin}
            error={
              Object.keys(errorCollection)?.includes("fin") &&
              errorCollection.fin
            }
            onFocus={() => updatedErrorByKey("fin")}
          />
        )}

        {legalStatusSelected == "5" && (
          <CustomTextField
            title="Malaysian I/C"
            value={malaysianIC}
            onChangeText={setMalaysianIC}
            require
            error={
              Object.keys(errorCollection)?.includes("malaysian_ic") &&
              errorCollection.malaysian_ic
            }
            onFocus={() => updatedErrorByKey("malaysian_ic")}
          />
        )}

        {(legalStatusSelected == "6" || legalStatusSelected === "7") && (
          <CustomTextField
            title="Passport"
            value={passport}
            onChangeText={setPassport}
            require
            error={
              Object.keys(errorCollection)?.includes("passport") &&
              errorCollection.passport
            }
            onFocus={() => updatedErrorByKey("passport")}
          />
        )}
        <CustomSelectField
          title="Gender"
          value={gender}
          onChangeOption={(option) => {
            setGender(option);
          }}
          placeholder="Select Gender"
          option={[
            { key: "Male", value: "Male" },
            { key: "Female", value: "Female" },
          ]}
          error={
            Object.keys(errorCollection)?.includes("gender") &&
            errorCollection.gender
          }
          onFocus={() => updatedErrorByKey("gender")}
          require={true}
        /> <CustomSelectField
        title="Religion"
        value={religion}
        onChangeOption={(option) => {
          setReligion(option);
        }}
        placeholder="Select Religion"
        option={
          relisionList?.map((item: any) => ({
            key: item.religion_name,
            value: item.id,
          })) || []
        }
        error={
          Object.keys(errorCollection)?.includes("religion") &&
          errorCollection.religion
        }
        onFocus={() => updatedErrorByKey("religion")}
        require={true}
      />
      
        <CustomSelectField
          title="Race"
          value={race}
          onChangeOption={(option) => {
            setRace(option);
          }}
          placeholder="Select Race"
          option={
            raceList?.map((item: any) => ({
              key: item.race_name,
              value: item.id,
            })) || []
          }
          error={
            Object.keys(errorCollection)?.includes("race") &&
            errorCollection.race
          }
          onFocus={() => updatedErrorByKey("race")}
          require={true}
        />
       

        {( religion == "4" && ["1","3","4"]?.includes(race || "" )&&
      <CustomeCkeckboxField
      title={`Deduct ${deduction?.find((item)=>item?.id==race)?.value} for Muslim-${raceList?.find((item)=>item?.['id']==race)?.['race_name']}`}
          value={dual_shg_deduction}
          onChange={() => set_Dual_shg_deduction((p) => !p)}
          error={
            Object.keys(errorCollection)?.includes("is_dual_shg_deduction") &&
            errorCollection.is_dual_shg_deduction
          }
        />
)}
        {/*  */}
        <CustomeCkeckboxField
          title="SDL Exempt"
          value={sdl}
          onChange={() => setSdl((p) => !p)}
          error={
            Object.keys(errorCollection)?.includes("is_sdl") &&
            errorCollection.is_sdl
          }
          // onFocus={() => updatedErrorByKey("is_sdl")}
        />
        <CustomeCkeckboxField
          title="CPF Exempt"
          value={cpf}
          onChange={() => setCpf((p) => !p)}
          error={
            Object.keys(errorCollection)?.includes("is_cpf") &&
            errorCollection.religion
          }
          disabled={
            legalStatusSelected &&
            legalStatusSelected !== "1" &&
            legalStatusSelected !== "2"
          }
          // onFocus={() => updatedErrorByKey("religion")}
        />
        <CustomTextField
          title="Email"
          // disabled={is_contain_finlaized_payslip}
          value={email}
          onBlur={handleEmailValidate}
          onChangeText={(text) => {
            validateEmail(text);
            setEmail(text);
          }}
          error={
            Object.keys(errorCollection)?.includes("email") &&
            errorCollection.email
          }
          onFocus={() => updatedErrorByKey("email")}
          require={true}
        />
        <CustomNumberField
          title="Hand Phone"
          value={phone}
          onBlur={handlePhoneValidation}
          placehoder=""
          removeNumIcon={true}
          onChangeText={(text) => {
            validatePhone(text);
          }}
          note="enter 8 digit handphone number without +65 area code"
          error={
            Object.keys(errorCollection)?.includes("phone") &&
            errorCollection.phone
          }
          onFocus={handlePhoneValidation}
        />
        <CustomSelectField
          title="Payment Method"
          value={paymentMethod}
          placeholder="Select Payment Method"
          onChangeOption={(option) => {
            setPaymentMethod(option);
          }}
          option={payMethods?.map((item: any) => ({
            key: item.payment_method_name,
            value: item.id,
          }))}
          error={
            Object.keys(errorCollection)?.includes("payment_method") &&
            errorCollection.payment_method
          }
          onFocus={() => updatedErrorByKey("payment_method")}
          require={true}
        />
        {paymentMethod == "5" && (
          <div className="new-employee">
            <h4>Bank Details</h4>
          </div>
        )}

        {paymentMethod == "5" && (
          <CustomGroupSelectField
            title="Bank Name"
            value={bankValue}
            placeholder="Select Bank"
            onChangeOption={(option) => {
              setBankValue(option);
            }}
            option={bankList}
            error={
              Object.keys(errorCollection)?.includes("bank_name") &&
              errorCollection.bank_name
            }
            onFocus={() => updatedErrorByKey("bank_name")}
            require={true}
          />
        )}
        {paymentMethod == "5" && (
          <CustomTextField
            title="Account Number"
            value={alphaNumericInput}
            onChangeText={(text) => {
              setAlphaNumericInput(text);
              checkAccountNoAlphanumeric(text);
            }}
            error={
              Object.keys(errorCollection)?.includes("bank_account_number") &&
              errorCollection.bank_account_number
            }
            onFocus={() => updatedErrorByKey("bank_account_number")}
            require={true}
          />
        )}
        {paymentMethod == "5" && (
          <CustomTextField
            title="Branch Code"
            value={branchCode}
            require
            onChangeText={(text) => {
              setBranchCode(text);
              checBranchCodeAlphanumeric(text);
            }}
            error={
              Object.keys(errorCollection)?.includes("bank_branch_code") &&
              errorCollection.bank_branch_code
            }
            onFocus={() => updatedErrorByKey("bank_branch_code")}
          />
        )}
        <div className="new-employee">
          <h4>Address</h4>
        </div>
        <CustomSelectField
          title="Type"
          value={addressTypeSelected || ""}
          placeholder="Select address"
          onChangeOption={(option) => {
            handleAddressType(option);
          }}
          option={addressType?.map((item: any) => ({
            key: item.name,
            value: item.id,
          }))}
          error={
            Object.keys(errorCollection)?.includes("address_type") &&
            errorCollection.address_type
          }
          onFocus={() => updatedErrorByKey("address_type")}
          require={true}
        />
        {addressTypeSelected == "1" && (
          <>
            <div className="new-employee">
              <CustomTextField
                title="Block No"
                value={blockNo}
                onChangeText={(text) => {
                  setBlockNo(text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("block_no") &&
                  errorCollection.block_no
                }
                onFocus={() => updatedErrorByKey("block_no")}
                require={true}
              />
              <CustomTextField
                title="Street Name"
                value={street}
                onChangeText={(text) => {
                  setStreet(text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("street_name") &&
                  errorCollection.street_name
                }
                onFocus={() => updatedErrorByKey("street_name")}
              />
            </div>
            <div className="new-employee">
              <CustomNumberField
                title="Level No"
                value={level}
                placehoder=""
                onChangeText={(text) => {
                  setLevel(text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("level_no") &&
                  errorCollection.level_no
                }
                onFocus={() => updatedErrorByKey("level_no")}
              />
              <CustomNumberField
                title="Unit No"
                placehoder=""
                value={unit}
                onChangeText={(text) => {
                  setUnit(text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("unit_no") &&
                  errorCollection.unit_no
                }
                onFocus={() => updatedErrorByKey("unit_no")}
              />
            </div>
          </>
        )}
        {(addressTypeSelected == "2" || addressTypeSelected == "3") && (
          <>
            <CustomTextField
              title="Address Line1"
              value={address1}
              onChangeText={(text) => {
                setAddress1(text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_1") &&
                errorCollection.address_line_1
              }
              onFocus={() => updatedErrorByKey("address_line_1")}
            />
            <CustomTextField
              title="Address Line2"
              value={address2}
              onChangeText={(text) => {
                setAddress2(text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_2") &&
                errorCollection.address_line_2
              }
              onFocus={() => updatedErrorByKey("address_line_2")}
            />
            <CustomTextField
              title="Address Line3"
              value={address3}
              onChangeText={(text) => {
                setAddress3(text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_3") &&
                errorCollection.address_line_3
              }
              onFocus={() => updatedErrorByKey("address_line_3")}
            />
          </>
        )}
        {(addressTypeSelected == "1" ||
          addressTypeSelected == "2" ||
          addressTypeSelected == "3") && (
          <div
            style={{
              width: addressTypeSelected == "1" ? "50%" : "100%",
            }}
          >
            <CustomNumberField
              title="Postal Code"
              value={`${postalCode}`}
              placehoder=""
              removeNumIcon={true}
              onChangeText={(text) => {
                setPostalCode(text);
                checkPostalCodeNoAlphanumeric(text);
              }}
              error={
                Object.keys(errorCollection)?.includes("postal_code") &&
                errorCollection.postal_code
              }
              onFocus={() => updatedErrorByKey("postal_code")}
              require={true}
            />
          </div>
        )}
        {addressTypeSelected == "2" && (
          <CustomSelectField
            title="Country Code"
            value={countryCode}
            onChangeOption={(option) => {
              setCountryCode(option);
            }}
            placeholder="Select Country Code"
            option={
              countryList?.map((item: any) => ({
                key: item.nationality,
                value: item.id,
              })) || []
            }
            error={
              Object.keys(errorCollection)?.includes("country") &&
              errorCollection.country
            }
            onFocus={() => updatedErrorByKey("country")}
          />
        )}
        <div className="new-employee">
          <h4>Other Statutory Info</h4>
        </div>
        
        <div
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            display: "flex",
          }}
        >
          <CustomButton
            title="Cancel"
            type={"secondary"}
            onClick={handleNavigateBack}
          />
          <CustomButton title="Save" onClick={handleUpdateEmployee} />
        </div>
      </div>
    </div>
  );
};
export default EditEmployeeInfo;
