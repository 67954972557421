import { useContext, useEffect, useState } from "react";
import { updateMessage, updateUserIntialState } from "../../_features";
import { useNavigate } from "react-router-dom";

import { Button, Loader, Message, TextInput } from "../../components";
import { AuthService } from "../../_service";
import { Constant } from "../../config";
import { useAppDispatch, useAppSelector } from "../../_app";
import CreatePassword from "./CreatePassword";
import ResetPassword from "./ResetPassword";
import Signin from "./Signin";
import { AuthHeader } from "../../components/header";
import useFirebaseAnalytics from "../../hooks/useFirebaseAnalytics";
import moment from "moment";

import {
  BackToHomeLink,
  BackToHomeLinkWrap,
  Container,
  ContainerBox,
  DontRememberLink,
  DontRememberLinkSection,
  InputFieldWrapper,
  Title,
} from "./AuthStyles";
import { Utils } from "../../utils";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const loginMessage = useAppSelector((state) => state.message.login);
  const resetMessage = () => {
    dispatch(updateMessage({ message: "", page: "login", type: "error" }));
  };

  const [sending, setSending] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const logFirebaseEvent = useFirebaseAnalytics();

  useEffect(() => {
    if (user.name !== "") {
      navigate("/dashboard/manage-company");
    }
  }, [user.name]);

  const handleLogin = async () => {
    if (email === "") {
      dispatch(
        updateMessage({
          message: `Email field can not be empty`,
          page: "login",
          type: "error",
        })
      );
    } else if (password === "") {
      dispatch(
        updateMessage({
          message: `Password field can not be empty`,
          page: "login",
          type: "error",
        })
      );
    } else {
      try {
        setSending(true);
        const res = await AuthService._userLogin({
          username: email,
          password,
        });
        if (res.status === 200) {
          Utils.resetStoreData();
          console.log("Server = ", process.env.SERVER);
          
          if (res.data.user.email) {
            let key = "login_user";
            let logData = {
              user_id: res.data.user.id,
              name: res.data.user.name,
              // device_fingerprint: "using_client_js",
              event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
              platform: "web",
            };
            logFirebaseEvent(key, logData);

            dispatch(
              updateUserIntialState({
                email: res.data.user.email,
                id: res.data.user.id,
                name: res.data.user.name,
                refresh: res.data.refresh,
                access: res.data.access,
                defaultCompany:
                  res.data?.default_company?.default_company_info?.name ||
                  "undefined",
                defaultCompanyPayrunStartDate:
                  res.data?.default_company?.default_company_info?.payrun_start_date ,
                defaultCompnayId: res.data?.default_company?.default_company,
                actualDefaultCompanyId:
                  res.data?.default_company?.default_company,
                lastLoggedInTime: Date.now(),
                isSessionActive: true,
                menu: res.data.menu,
                groups: res.data.user.groups,
                permission:
                  res.data?.default_company?.access &&
                  res.data?.default_company?.access !== null
                    ? res.data?.default_company?.access
                    : "full",
                current_payrun_date:
                  res.data?.default_company?.default_company_info
                    ?.current_payrun_date ||
                  res.data?.default_company?.default_company_info
                    ?.payrun_start_date,
              })
            );
            sessionStorage.setItem("activeTab", "true");
          }
        } else {
          dispatch(
            updateMessage({
              message: `Couldn't log you in as '${email}'. Please try again or reset your password if this persists.`,
              page: "login",
              type: "error",
            })
          );
        }
        setSending(true);
      } catch (e: any) {
        setSending(false);
        dispatch(
          updateMessage({
            message: `Couldn't log you in as '${email}'. Please try again or reset your password if this persists.`,
            page: "login",
            type: "error",
          })
        );
      }
    }
  };

  return (
    <>
      {sending && <Loader />}
      <AuthHeader />
      <Container>
        <ContainerBox>
          <Title>{Constant.login.login}</Title>
          <InputFieldWrapper>
            <Message data={loginMessage} close={resetMessage} />
            <TextInput
              labal={Constant.login.email}
              type="email"
              placeholder="Email address associated with your account"
              value={email}
              onChange={setEmail}
              style={{ textTransform: "none" }}
              containerStyle={{ marginTop: "1rem" }}
            />
            <DontRememberLinkSection>
              <DontRememberLink to="/password-resets">
                {Constant.login.dont_remember_password}
              </DontRememberLink>
              <TextInput
                labal={Constant.login.password}
                type="password"
                placeholder="Enter password (leave blank if not chosen yet)"
                value={password}
                onChange={setPassword}
                style={{ textTransform: "none" }}
                onSubmit={handleLogin}
              />
            </DontRememberLinkSection>
            <Button title={"Login"} onClick={handleLogin} />
          </InputFieldWrapper>
          <BackToHomeLinkWrap>
            <BackToHomeLink to="/">
              {Constant.common.back_to_home_page}
            </BackToHomeLink>
          </BackToHomeLinkWrap>
        </ContainerBox>
      </Container>
    </>
  );
};

export default Login;
export { CreatePassword, ResetPassword, Signin };
