import { URL } from "../config";
import apiClient from "./BaseService";

const _getPayslipReportDetails = async (
  companyId: string,
  month: string,
  year: string,
) =>
  apiClient.get(
    `${URL.GET_PAYSLIP_REPORTS}?month=${month}&company=${companyId}&year=${year}`,
  );

export const PayslipReportService = {
  _getPayslipReportDetails,
};
