import {
  Button,
  Typography,
  Box,
  Paper,
  Container,
  Modal,
  styled,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PDFViewer } from "@react-pdf/renderer";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import { TfiDownload } from "react-icons/tfi";
import { useAppSelector } from "../../_app";
import { Notify, Utils } from "../../utils";
import { NavLink } from "react-router-dom";
import { EmployeeService, PayrunService } from "../../_service";
import { CommonModal, CustomButton, Loader } from "../../components";
import { useEffect, useState } from "react";
import PayrunPdf from "../../components/pdfs/PayrunDocuments";
import { CreatePayrunModal } from "../../components";
import FinalizedPayrun from "../../components/payrun/FinalizedPayrun";
import { Constant } from "../../config";
import { Colors } from "../../config/color";
import moment from "moment";
import useFirebaseAnalytics from "../../hooks/useFirebaseAnalytics";
import merge from "lodash.merge";
import { TailSpin } from "react-loader-spinner";

// convertToDateString

const PayrunItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const PayslipContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "90px",
  border: "1px solid #e0e0e0",
  height: "100%",
}));

const Title = styled(Box)(() => ({
  background: "#e0e0e0",
  textAlign: "center",
}));

const Value = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  height: "48px",
  fontSize: "22px",
}));

const Link = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "28px",
  fontSize: "22px",
  border: "1px solid #e0e0e0",
}));

function Payrun() {
  const theme = useTheme();
  const id = useAppSelector((state) => +state.user.defaultCompnayId);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  // const [accountingInfoData, setAccoutingInfoData] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [activeDataModal, setActiveDataModal] = useState<string | null>(null);
  const [pdfModal, setPdfModal] = useState<boolean>(false);
  const [pdfViewModal, setPdfViewModal] = useState<boolean>(false);
  const [currentPayrun, setCurrentPayrun] = useState<null | number>(null);
  const [finalizedData, setFinalizedData] = useState<any[]>([]);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [dateToApprove, setDateToApprove] = useState("");
  const [isAlreadySentToTellboss, setIsAlreadySentToTellboss] =
    useState<boolean>(false);

  const [dataView, setDataView] = useState<any[]>([]);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const companyName = useAppSelector((state) => state.user.defaultCompany);

  
  
  const logFirebaseEvent = useFirebaseAnalytics();
  
  const user = useAppSelector((state) => state.user);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );
  
  const [year, setYear] = useState(currentPayrunDate?.split("-")?.[0]);

  const [showFetchButton, setShowFetchButton] = useState(true);


  const get = async () => {
    try {
      setLoading(true);
      const res1 = await PayrunService._getPayrunByCompany(id, year);
      // const res2 = await PayrunService._getAllAccountingInfo(companyId,)

      // setData(res1);

      setData((prevState:any) => merge({}, prevState, res1));

      // if(Object.keys(res1?.payrun_finalized_payslips || {})?.length || Object.keys(res1?.pending_payslips || {})?.length){
      //   setData((prevState:any) => merge({}, prevState, res1));
      // } 


      if(!Object.keys(res1?.payrun_finalized_payslips || {})?.length) {
        setShowFetchButton(false);
      }
      // setAccoutingInfoData(res2?.data?.data);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const sendForApproval = async () => {
    setLoading(true);
    setIsApprovalModalOpen(() => false);
    const dataToApprove = {
      company: companyId,
      payslip_date: dateToApprove,
    };

    try {
      const { status } = await EmployeeService._sendForApproval(dataToApprove);

      if (status >= 200 && status < 300) {
        Notify("Payslips Sent for Approval", 1);
        setIsAlreadySentToTellboss(() => true);
        setLoading(false);
      }
    } catch (error: any) {
      if (error?.response?.data?.paylsip) {
        Notify(error?.response?.data?.paylsip, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
      setLoading(false);
    }
    // setIsApprovalModalOpen(() => false);
    setDateToApprove(() => "");
  };

  useEffect(() => {
    if(year){
      get();
    }
  }, [year]);

  // const getAllAccountingData = async () => {
  //   try {
  //     const res = await PayrunService._getAllAccountingInfo(companyId,)
  //     console.log("theRes accounting", res.data.data)
  //     // setData(res.data.data);
      
  //   } catch (e:any) {
  //     console.error(e);
  //   }
  // }
  // useEffect(() => {
  //   if(companyId) {
  //     getAllAccountingData()
  //   }
  // }, [companyId]);

  const handleViewAndPreView = (data: any) => {
    setDataView(() => data);
    setPdfViewModal(() => true);
  };
  const handleOpenModal = (data: any[]) => {
    setFinalizedData(() => data);
    setOpen(true);
  };

  const handleUnFinalisation = async () => {
    setLoading(true);
    setOpen(false);
    try {
      const ids = finalizedData?.map((_) => _.id);
      const formData = new FormData();
      formData.append("finalize", "false");
      formData.append("payslip_ids", ids.join(","));
      formData.append("company", `${id}`);
      const res = await PayrunService._bulkPayslipFinalize(formData);
      // setOpen(false);

      if(res.status >= 200 && res.status < 300){
        // firebase log here
        let key = "unfinalize";
          let data = {
            user_id: user.id,
            name: user.name,
            // device_fingerprint: "using_client_js",
            event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
            platform: "web",
          };
          logFirebaseEvent(key, data);
      }
      get();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleShowMore = () => {
    let currentYear = parseInt(year);
    setYear(`${currentYear - 1}`) 
  }

  return (
    <div
      style={{
        position: "relative",
        maxHeight: "92vh",
        overflow: "scroll",
      }}
    >
      {loading && <Loader />}
      <div className="company-header-wrap">
        <h2>Pay Runs</h2>
      </div>
      <Paper
        sx={{
          mb: "20px",
        }}
        elevation={3}
      >
        <Box sx={{ background: "#4a5e71", padding: "12px 20px" }}>
          <Typography color="white">Pay Runs</Typography>
        </Box>
        <Container
          maxWidth="lg"
          sx={{ padding: "20px", marginLeft: "000.1px" }}
        >
          <>
            {Object.entries(data?.pending_payslips || [])?.map(
              (payrun: any, index: number) => {
                return (
                  <PayrunItem key={String(index)}>
                    <div>
                      <Typography variant="h6" component="h5">
                        The month ending {Utils.convertToDateString(payrun[0])}
                      </Typography>

                      {/*  payRunReady for createPayRun -> meaning all payslips have been approved by TellBoss */}
                      {payrun[1]?.is_payrun_ready && (
                        <>
                          <p
                            style={{
                              color: "#03b381",
                              marginTop: 15,
                              marginBottom: 15,
                              fontSize: "1.35rem",
                              fontWeight: 600,
                              outline: "black",
                            }}
                          >
                            <em>
                              Received Boss approval on{" "}
                              {/* {Utils._dateToShow(
                                payrun[1]?.finalized[0]?.updated_at
                              )} */}
                              {moment(payrun[1]?.approval_date).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </em>
                          </p>
                          <p
                            style={{
                              color: "#03b381",
                              fontSize: "1.35rem",
                              fontWeight: 600,
                              outline: "black",
                              fontStyle: "italic",
                              marginBottom: 15,
                            }}
                          >
                            Click the button below to close this payroll and
                            move to next month's payroll.
                          </p>
                          {/* <Button
                              onClick={() => {
                                setCreateModal(true);
                                setActiveDataModal(payrun[0]);
                              }}
                              sx={{
                                mt: "14px",
                                "&:hover": {
                                  background: "#087ca7",
                                  color: "white",
                                },
                              }}
                              variant="outlined"
                            >
                              Create pay run
                            </Button> */}
                          <CustomButton
                            title="Close Current Month Payroll and Create Next Month Payroll"
                            styles={{
                              width: 500,
                              height: 40,
                              fontWeight: 600,
                              padding: 12,
                              margin: 32,
                            }}
                            onClick={() => {
                              setCreateModal(true);
                              setActiveDataModal(payrun[0]);
                            }}
                          />
                          {createModal && activeDataModal === payrun[0] && (
                            <CreatePayrunModal
                              open={createModal}
                              handleClose={() => {
                                get();
                                setCreateModal(() => false);
                              }}
                              date={payrun[0]}
                              pendingCount={payrun[1].pending?.length ?? 0}
                              companyId={id}
                            />
                          )}
                        </>
                      )}

                      {/* 
                        there are NO pending payslips, and
                        payrun is NOT ready, and
                        payroll has NOT been sent for approval to "TellBoss", and
                        payroll has NOT been sent back for revision
                        -> show "send for for approval button"
                      */}
                      {!payrun[1].pending?.length &&
                        !payrun[1]?.is_payrun_ready &&
                        !payrun[1]?.is_already_sent_to_tellboss &&
                        !isAlreadySentToTellboss &&
                        !payrun[1]?.is_send_revised_payslips_to_tellboss && (
                          <Button
                            onClick={() => {
                              setIsApprovalModalOpen(() => true);
                              setDateToApprove(() => `${payrun[0]}`);
                            }}
                            sx={{
                              mt: "14px",
                              "&:hover": {
                                background: "#087ca7",
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            disabled={loading}
                          >
                            Click to send payroll to "TellBoss" for approval
                          </Button>
                        )}

                      {/* 
                        there are NO pending payslips, and
                        payroll has been sent for approval to Tellboss, and
                        payRun is NOT ready (i.e. we haven't received the approval yet)
                      */}
                      {!payrun[1].pending?.length &&
                        (payrun[1]?.is_already_sent_to_tellboss ||
                          isAlreadySentToTellboss) &&
                        !payrun[1]?.is_payrun_ready && (
                          <Button
                            sx={{
                              mt: "14px",
                              "&.Mui-disabled": {
                                background: "#ff6665",
                                color: "#ffffff",
                                fontWeight: 600,
                              },
                            }}
                            disabled
                            variant="contained"
                          >
                            Already Sent to Tellboss
                          </Button>
                        )}

                      {/* 
                        there are NO pending payslips, and
                        payrun is NOT ready, and
                        has NOT been already sent, and
                        payroll is back for revision
                        -> show "send revised payroll for approval button"
                      */}
                      {!payrun[1].pending?.length &&
                        !payrun[1]?.is_payrun_ready &&
                        !payrun[1]?.is_already_sent_to_tellboss &&
                        !isAlreadySentToTellboss &&
                        payrun[1]?.is_send_revised_payslips_to_tellboss && (
                          <Button
                            onClick={() => {
                              setIsApprovalModalOpen(() => true);
                              setDateToApprove(() => `${payrun[0]}`);
                            }}
                            sx={{
                              mt: "14px",
                              color: Colors.GREEN_1,
                              "&:hover": {
                                background: Colors.GREEN_1,
                                color: "white",
                              },
                            }}
                            variant="outlined"
                            disabled={loading}
                          >
                            Click to send Revised payroll to "TellBoss" for
                            approval
                          </Button>
                        )}
                    </div>

                    <Box
                      sx={{
                        [theme.breakpoints.down("lg")]: {
                          mt: "8px",
                        },
                      }}
                    >
                      <p>Payslips</p>
                      <Box mt={2} sx={{ display: "flex" }}>
                        <PayslipContainer mr={2}>
                          <Title>Total</Title>
                          <Value>
                            {payrun[1]?.finalized?.length +
                              payrun[1]?.pending?.length}
                          </Value>
                        </PayslipContainer>
                        <PayslipContainer mr={2}>
                          <Title>Finished</Title>
                          <Value>{payrun[1]?.finalized?.length}</Value>
                          {/* view finalised payruns */}
                          {payrun[1]?.finalized?.length > 0 && (
                            <>
                              <Link key={payrun[0]}>
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    color: "#059bbf",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleViewAndPreView(payrun[1]?.finalized);
                                  }}
                                >
                                  View
                                </p>
                              </Link>
                              <Link>
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    color: "#059bbf",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleOpenModal(payrun[1]?.finalized)
                                  }
                                >
                                  UnFinalise
                                </p>
                              </Link>
                            </>
                          )}
                        </PayslipContainer>
                        <PayslipContainer>
                          <Title>Pending</Title>
                          <Value>{payrun[1]?.pending?.length}</Value>
                          {/* prevview pending payrun */}
                          {pdfModal && currentPayrun === payrun[0] && (
                            <Modal
                              open={pdfModal}
                              onClose={() => setPdfModal(false)}
                            >
                              <Paper
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  background: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                              >
                                <Box
                                  className="close-icon"
                                  sx={{
                                    position: "absolute",
                                    top: 20,
                                    right: 20,
                                  }}
                                  onClick={() => setPdfModal(false)}
                                >
                                  <IconContext.Provider
                                    value={{ color: "#f00", size: "30px" }}
                                  >
                                    <AiOutlineCloseCircle />
                                  </IconContext.Provider>
                                </Box>
                                <PDFViewer width="90%" height="90%">
                                  <PayrunPdf documents={payrun[1]?.pending} />
                                </PDFViewer>
                              </Paper>
                            </Modal>
                          )}
                          {/* view finalized payrun */}
                          {pdfViewModal && (
                            <Modal
                              open={pdfViewModal}
                              onClose={() => setPdfViewModal(() => false)}
                            >
                              <Paper
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  background: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                              >
                                <Box
                                  className="close-icon"
                                  sx={{
                                    position: "absolute",
                                    top: 20,
                                    right: 20,
                                  }}
                                  onClick={() => setPdfViewModal(() => false)}
                                >
                                  <IconContext.Provider
                                    value={{ color: "#f00", size: "30px" }}
                                  >
                                    <AiOutlineCloseCircle />
                                  </IconContext.Provider>
                                </Box>
                                <PDFViewer width="90%" height="90%">
                                  <PayrunPdf
                                    documents={dataView}
                                    finalized={true}
                                  />
                                </PDFViewer>
                              </Paper>
                            </Modal>
                          )}
                          {payrun[1]?.pending?.length > 0 && (
                            <>
                              <Link>
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    color: "#059bbf",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPdfModal(true);
                                    setCurrentPayrun(payrun[0]);
                                  }}
                                >
                                  Preview
                                </p>
                              </Link>
                              <Link>
                                <NavLink
                                  to="/dashboard/bulk_finalisation"
                                  state={{
                                    data: payrun[1]?.pending,
                                    date: payrun[0],
                                  }}
                                  style={{
                                    fontSize: "1rem",
                                    color: "#059bbf",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                >
                                  Finalise
                                </NavLink>
                              </Link>
                            </>
                          )}
                        </PayslipContainer>
                      </Box>
                    </Box>
                  </PayrunItem>
                );
              }
            )}
          </>
        </Container>
      </Paper>

      {Object.entries(data?.payrun_finalized_payslips ?? [])?.map(
        (payrun: any, index: number) => (
          <FinalizedPayrun
            key={String(index)}
            payrun={payrun}
            companyName={companyName}
          />
        )
      )}

      {showFetchButton && (
        <div
          onClick={handleShowMore}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.25rem",
            marginBottom: "1.5rem",
            cursor: "pointer",
            gap: "1rem",
            flexDirection: "column",
          }}
        >
          {loading && Object.keys(data)?.length > 1 ? (
            <TailSpin
              visible={true}
              height="60"
              width="60"
              color="#3498db"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          ) : (
            <>
              <TfiDownload size={35} />
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                Show More
              </p>
            </>
          )}
        </div>
      )}

      {/* </Container> */}
      <CommonModal
        handleClose={() => {
          setOpen(false);
        }}
        open={open}
        updateData={handleUnFinalisation}
        body={`${finalizedData?.length} payslip(s) will be unfinalised in bulk. Please only use
        this option if you need to change the majority of your payslips for
        the current period. If you are at all uncertain, we would
        strongly advise that you unfinalise payslips individually to prevent
        any unexpected changes. Are you sure you want to continue?`}
        loading={loading}
      />
      <CommonModal
        handleClose={() => setIsApprovalModalOpen(() => false)}
        open={isApprovalModalOpen}
        updateData={sendForApproval}
        body={`Make sure you have all the payslips finalised before sending for approval`}
        heading="Send to TellBoss for approval"
        conformButtonText="Confirm"
        cancelButtonText="Cancel"
        loading={loading}
      />
    </div>
  );
}

export default Payrun;
