import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import {
  EmployeeService,
  HelperService,
  CompanyService,
} from "../../../_service";
import validator from "validator";
import "react-datepicker/dist/react-datepicker.css";

import { Notify, Utils, Validate } from "../../../utils";
import {
  CommonModal,
  ConfirmationModal,
  FormErrorMessage,
  Loader,
} from "../../../components";
import {
  ConfirmationModalInterface,
  PayFrequencyInterface,
} from "../../../_types";
import {
  EmployeeInfoInterface,
  LegalStatusInterface,
  AddressInterface,
  BankInfoInterface,
} from "../../../_types/AddEmployeeInterface";
import EmployeeHeader from "./employeeHeader";
import "./manageEmployee.css";
import { useAppSelector } from "../../../_app";
import { useNavigate } from "react-router-dom";
import {
  CustomDateField,
  CustomGroupSelectField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  CustomeCkeckboxField,
} from "../../../components/customFormField";
import { CustomButton } from "../../../components/customButton";
import { RegularHoursFields } from "../component";
import Require from "../../../components/customFormField/Require";
import { useDispatch } from "react-redux";
import { updateAppSettingId } from "../../../_features";
import moment from "moment";
import useFirebaseAnalytics from "../../../hooks/useFirebaseAnalytics";
import CustomLoader from "../../../components/CustomLoader";
import { LoaderContainer } from "../../../CommonStyles";

const AddNewEmployee = () => {
  const navigate = useNavigate();

  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const logFirebaseEvent = useFirebaseAnalytics();

  const [loading, setLoading] = useState<boolean>(false)

  const [confirmationModalContent, setConfirmationModalContent] =
    useState<null | ConfirmationModalInterface>(null);
  const [employeeInfo, setEmployeeInfo] = useState<
    EmployeeInfoInterface & { start_permanent_date: string | null }
  >({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    pay_frequency: "",
    name_format: "Last Name First Name",
    job_title: "",
    date_of_birth: Utils._date("1990-01-01"),
    date_of_appointment: "",
    gender: "",
    company: companyId,
    is_sdl: false,
    is_cpf: false,
    is_dual_shg_deduction:false,
    race: null,
    payment_method: null,
    religion: null,
    emp_number: "",
    is_deduction_except: false,
    start_permanent_date: "",
    is_shg_deduction: true,
  });
  const [legalStatusInfo, setLegalStatusInfo] = useState<LegalStatusInterface>({
    nric: "",
    passport: "",
    malaysian_ic: "",
    fin: "",
    legal_status: "",
    nationality: "",
    start_permanent_date: "",
  });

  const [addressInfo, setAddressInfo] = useState<AddressInterface>({
    block_no: "",
    street_name: "",
    level_no: "",
    unit_no: "",
    postal_code: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    country: "",
    address_type: "",
  });

  const [bankInfo, setBankInfo] = useState<BankInfoInterface>({
    bank_name: "",
    bank_account_number: "",
    bank_branch_code: "",
  });
  const [manualEmpNumber, setManualEmpNumber] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const DISABLE_LIST = [
    "date_of_appointment",
    "job_title",
    "payment_method",
    "phone",
    "email",
  ];
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  const [legalStatus, setLegalStatus] = useState([]);
  const [countryList, setCountryList] = useState<any[]>([]);
  const [raceList, setRaceList] = useState([]);
  const [raceData, setRaceData] = useState([]);
  const [relisionList, setRelisionList] = useState([]);
  const [payMethods, setPayMethods] = useState([]);
  const [payFrequency, setPayFrequency] = useState<
    PayFrequencyInterface[] | null
  >(null);

  const [bankList, setBankList] = useState([]);
  const [addressType, setAddressType] = useState([]);

  const [editPrice, setEditPrice] = useState(false);
  const [price, setPrice] = useState(0.0);
  const [employeedId, setEmployeedId] = useState(null);
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const [cpfDeduction, setCpfDeduction] = useState(false);
  const [errorCollection, setErrorCollection] = useState<{
    [key: string]: any;
  }>({});

  const getPayFrequency = async () => {
    try {
      CompanyService._getPayFrequency(companyId).then((data) => {
        if (data.length > 0) {
          if (data[0]?.id) {
            handleEmployeeInfoChange("pay_frequency", data[0].id);
          }
          setPayFrequency(data);
        } else {
          CompanyService._createpayFrequency({
            id: parseInt(companyId),
          }).then((res) => {
            if (res.status == 200) {
              getPayFrequency();
            }
          });
        }
      });
    } catch (error) {
      console.error("_getPayFrequency", error);
    }
  };
  useEffect(() => {
    getPayFrequency();
  }, []);

  const handleEmployeeInfoChange = (
    target: string,
    value: string | number | boolean
  ) => {
    if (target === "email") {
      setIsDisable(() => false);
    }
    if (isDisable) {
      if (
        target === "date_of_appointment" ||
        target === "job_title" ||
        target === "payment_method" ||
        target === "phone" ||
        target === "email"
      ) {
        setEmployeeInfo((prev) => ({ ...prev, [target]: value }));
      }
    } else {
      setEmployeeInfo((prev) => ({ ...prev, [target]: value }));
    }
  };

  const handleLegalStatusInfoChange = (
    target: string,
    value: string | number | boolean
  ) => {
    !isDisable && setLegalStatusInfo((prev) => ({ ...prev, [target]: value }));
  };

  const handleAddressChange = (
    target: string,
    value: string | number | boolean
  ) => {
    !isDisable && setAddressInfo((prev) => ({ ...prev, [target]: value }));
  };

  const handleBankInfoChange = (target: string, value: string) => {
    !isDisable && setBankInfo((prev) => ({ ...prev, [target]: value }));
  };

  const updatedErrorByKey = (key: string, value: string = "") => {
    if (value === "") {
      let err = errorCollection;
      delete err[`${key}`];
      setErrorCollection(err);
    } else {
      setErrorCollection((prev) => ({ ...prev, [key]: value }));
    }
  };
  const handleAddEmployeeError = () =>
    Validate.addEmployeeDataValidate(
      employeeInfo,
      legalStatusInfo,
      addressInfo,
      bankInfo,
      updatedErrorByKey,
      manualEmpNumber
    );

  const handleAddNewEmployee = async () => {
   setErrorCollection({})
    if (handleAddEmployeeError()) {
      return;
    }
    setLoading(true);
    employeeInfo.job_title = employeeInfo.job_title.toUpperCase();
    try {
      const _payload = {
        employee_info: {
          ...employeeInfo,
          ...(!manualEmpNumber && { emp_number: null }),
        },
        ...(employeeInfo?.payment_method == 5
          ? {
              ...{ bank_info: bankInfo },
            }
          : {}),
        address_info: {
          ...(addressInfo.address_type == 1
            ? {
                postal_code: addressInfo.postal_code,
                street_name: addressInfo.street_name,
                level_no: addressInfo.level_no,
                unit_no: addressInfo.unit_no,
                block_no: addressInfo.block_no,
              }
            : {}),
          ...(addressInfo.address_type == 2 || addressInfo.address_type == 3
            ? {
                address_line_1: addressInfo.address_line_1,
                address_line_2: addressInfo.address_line_2,
                address_line_3: addressInfo.address_line_3,
                postal_code: addressInfo.postal_code,
              }
            : {}),
          ...(addressInfo.address_type == 2
            ? {
                country: addressInfo.country,
              }
            : {}),
          address_type: addressInfo.address_type,
        },
        legal_status_info: {
          ...(legalStatusInfo.legal_status == 1 ||
          legalStatusInfo.legal_status == 2
            ? { nric: legalStatusInfo.nric }
            : {}),
          ...(legalStatusInfo.legal_status == 6 || legalStatusInfo.legal_status === "7"
            ? { passport: legalStatusInfo.passport }
            : {}),
          ...(legalStatusInfo.legal_status == 5
            ? { malaysian_ic: legalStatusInfo.malaysian_ic }
            : {}),
          ...(legalStatusInfo.legal_status == 3 ||
          legalStatusInfo.legal_status == 4
            ? { fin: legalStatusInfo.fin }
            : {}),
          ...(legalStatusInfo.legal_status == 2
            ? {
                start_permanent_date:
                  legalStatusInfo.start_permanent_date || null,
              }
            : {}),
          ...(legalStatusInfo.legal_status == 2 ||
          legalStatusInfo.legal_status == 3 ||
          legalStatusInfo.legal_status == 4 ||
          legalStatusInfo.legal_status == 6 ||
           legalStatusInfo.legal_status == 7)
            ? { nationality: legalStatusInfo.nationality }
            : {},
          legal_status: legalStatusInfo.legal_status,
        },
      };

      
    if ( !(employeeInfo?.religion === "4" && employeeInfo?.race === "1"||"3"||"4"))
      {
      
        _payload.employee_info.is_dual_shg_deduction = false
      }

      const res = await EmployeeService._addNewEmployee(_payload);
   
      setEmployeedId(res?.data?.id);
      if (res.status == 201) {
        // firebase log here
        let key = "create_employee";
        let data = {
          user_id: user.id,
          name: user.name,
          // device_fingerprint: "using_client_js",
          event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
          platform: "web",
        };
        logFirebaseEvent(key, data);


        setErrorCollection({});
        Notify("Employee Added Successfully", 1);
        clearData();
        setStep(2);
        setLoading(false);
      }
    } catch (e: any) {
      if (e.response.status == 403 || e.response.status == 400) {
        if (
          typeof e.response.data === "string" &&
          (e.response.data || "")?.includes("already exists")
        ) {
          Notify("Employee already exists with this company", 0);
        } else {
          const err: { [key: string]: string; value: string } | any = {};
          Object.entries(e.response.data || {}).forEach((item: any) => {
            err[item[0]] = item[1];
          });
          setErrorCollection(err);
          window.scrollTo(0, 0);
        }
      }

      console.error(e);
      setLoading(false);
    }
  };

  const clearData = () => {
    setAddressType([]);
    setBankList([]);
    setPayMethods([]);
    setRelisionList([]);
    setRaceList([]);
    setCountryList([]);
    setLegalStatus([]);
    setLegalStatusInfo({
      nric: "",
      passport: "",
      malaysian_ic: "",
      fin: "",
      legal_status: "",
      nationality: "",
      start_permanent_date: "",
    });
    setAddressInfo({
      block_no: "",
      street_name: "",
      level_no: "",
      unit_no: "",
      postal_code: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      country: "",
      address_type: "",
    });
    setBankInfo({
      bank_name: "",
      bank_account_number: "",
      bank_branch_code: "",
    });
  };

  const get = async () => {
    setLoading(true);
    // legal status
    try {
      const LegalStatus = await HelperService._getLegalStatus();
      setLegalStatus(() => LegalStatus.data);
    } catch (e: any) {
      console.error(e);
    }
    try {
      const countryList = await HelperService._getCountry();
      setCountryList(() => countryList.data);
    } catch (e: any) {
      console.error(e);
    }
    try {
      const raceList = await HelperService._getRace();
      setRaceList(() => raceList.data);
      setRaceData(() => raceList.data);
    } catch (e: any) {
      console.error(e);
    }
    try {
      const relisionList = await HelperService._getReligion();
      setRelisionList(() => relisionList.data);
    } catch (e: any) {
      console.error(e);
    }
    try {
      const payMethods = await HelperService._getPaymentMethods();
      setPayMethods(() => payMethods.data);
    } catch (e: any) {
      console.error(e);
    }
    try {
      const bankList = await HelperService._getBankList();
      setBankList(() => bankList.data);
    } catch (e: any) {
      console.error(e);
    }
    try {
      const addressType = await HelperService._getAddressTypes();
      setAddressType(() => addressType.data);
    } catch (e: any) {
      console.error(e);
    }
    try {
      const res = await CompanyService._getEmployeeNumber(companyId);
      if (res?.setting?.mode == "Manual") {
        // setEmployeeInfo((prev) => ({
        //   ...prev,
        //   emp_number: res?.setting?.first_emp_no,
        // }));
        setManualEmpNumber(true);
      } else {
        setManualEmpNumber(false);
      }
    } catch (e: any) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  const validateEmail = (email: any) => {
    if (!validator.isEmail(email)) {
      updatedErrorByKey("email", "Enter valid Email!");
    } else {
      updatedErrorByKey("email", "");
    }
  };
  const validatePhone = (e: string) => {
    if (e.length <= 8) {
      handleEmployeeInfoChange("phone", e);
    }
  };

  const [mondayChecked, setMondayChecked] = useState(true);
  const [tuesdayChecked, setTuesdayChecked] = useState(true);
  const [wednesdayChecked, setWednesdayChecked] = useState(true);
  const [thursdayChecked, setThursdayChecked] = useState(true);
  const [fridayChecked, setFridayChecked] = useState(true);
  const [saturdayChecked, setSaturdayChecked] = useState(false);
  const [sundayChecked, setSundayChecked] = useState(false);

  const [perDayHours, setPerDayHours] = useState<any>(8.8);
  const [schedule, setSchedule] = useState<string | number>(1);
  const [hourlyPaid, setHourlyPaid] = useState(false);

  const [sundayPartialDay, setSundayPartialDay] = useState(false);
  const [sundayPartialHours, setSundayPartialHours] = useState("");

  const [mondayPartialDay, setMondayPartialDay] = useState(false);
  const [mondayPartialHours, setMondayPartialHours] = useState("");

  const [tuesdayPartialDay, setTuesdayPartialDay] = useState(false);
  const [tuesdayPartialHours, setTuesdayPartialHours] = useState("");

  const [wednesdayPartialDay, setWednesdayPartialDay] = useState(false);
  const [wednesdayPartialHours, setWednesdayPartialHours] = useState("");

  const [thursdayPartialDay, setThursdayPartialDay] = useState(false);
  const [thursdayPartialHours, setThursdayPartialHours] = useState("");

  const [fridayPartialDay, setFridayPartialDay] = useState(false);
  const [fridayPartialHours, setFridayPartialHours] = useState("");

  const [saturdayPartialDay, setSaturdayPartialDay] = useState(false);
  const [saturdayPartialHours, setSaturdayPartialHours] = useState("");

  const handleAddingHoursData = () => {
    let flag = true;
    const working_days_info = [
      {
        day: "mon",
        partial: mondayPartialDay ? true : false,
        hours: mondayPartialDay ? mondayPartialHours : null,
        day_off: !mondayChecked,
      },
      {
        day: "tue",
        partial: tuesdayPartialDay ? true : false,
        hours: tuesdayPartialDay ? tuesdayPartialHours : null,
        day_off: !tuesdayChecked,
      },
      {
        day: "wed",
        partial: wednesdayPartialDay ? true : false,
        hours: wednesdayPartialDay ? wednesdayPartialHours : null,
        day_off: !wednesdayChecked,
      },
      {
        day: "thu",
        partial: thursdayPartialDay ? true : false,
        hours: thursdayPartialDay ? thursdayPartialHours : null,
        day_off: !thursdayChecked,
      },
      {
        day: "fri",
        partial: fridayPartialDay ? true : false,
        hours: fridayPartialDay ? fridayPartialHours : null,
        day_off: !fridayChecked,
      },
      {
        day: "sat",
        partial: saturdayPartialDay ? true : false,
        hours: saturdayPartialDay ? saturdayPartialHours : null,
        day_off: !saturdayChecked,
      },
      {
        day: "sun",
        partial: sundayPartialDay ? true : false,
        hours: sundayPartialDay ? sundayPartialHours : null,
        day_off: !sundayChecked,
      },
    ];

    for (
      let workingDaysIndex = 0;
      workingDaysIndex < working_days_info.length;
      workingDaysIndex++
    ) {
      let hours = working_days_info[workingDaysIndex]?.hours;
      if (working_days_info[workingDaysIndex]?.partial && !hours) {
        Notify(`Please check partial hours`, 0);
        flag = false;
      }
    }
    if (Utils.isEmpty(perDayHours)) {
      Notify(`Hours per day cannot be empty`, 0);
      flag = false;
    }
    if (Utils.isEmpty(price)) {
      Notify(`Full days per week cannot be empty`, 0);
      flag = false;
    }
    return flag;
  };
  const updateOverritePrice = () => {
    const partialDay = 1 / parseFloat(perDayHours || "8");
    const monday =
      mondayChecked && !mondayPartialDay
        ? 1
        : mondayPartialHours
        ? parseFloat(mondayPartialHours) * partialDay
        : 0;
    const tuesday =
      tuesdayChecked && !tuesdayPartialDay
        ? 1
        : tuesdayPartialHours
        ? parseFloat(tuesdayPartialHours) * partialDay
        : 0;
    const wednesday =
      wednesdayChecked && !wednesdayPartialDay
        ? 1
        : wednesdayPartialHours
        ? parseFloat(wednesdayPartialHours) * partialDay
        : 0;
    const thursday =
      thursdayChecked && !thursdayPartialDay
        ? 1
        : thursdayPartialHours
        ? parseFloat(thursdayPartialHours) * partialDay
        : 0;
    const friday =
      fridayChecked && !fridayPartialDay
        ? 1
        : fridayPartialHours
        ? parseFloat(fridayPartialHours) * partialDay
        : 0;
    const saturday =
      saturdayChecked && !saturdayPartialDay
        ? 1
        : saturdayPartialHours
        ? parseFloat(saturdayPartialHours) * partialDay
        : 0;
    const sunday =
      sundayChecked && !sundayPartialDay
        ? 1
        : sundayPartialHours
        ? parseFloat(sundayPartialHours) * partialDay
        : 0;
    setPrice(
      monday + tuesday + thursday + wednesday + friday + saturday + sunday
    );
  };
  useEffect(() => {
    updateOverritePrice();
  }, [
    mondayChecked,
    tuesdayChecked,
    wednesdayChecked,
    thursdayChecked,
    fridayChecked,
    saturdayChecked,
    sundayChecked,
    mondayPartialDay,
    tuesdayPartialDay,
    wednesdayPartialDay,
    thursdayPartialDay,
    saturdayPartialDay,
    sundayPartialDay,
    mondayPartialHours,
    tuesdayPartialHours,
    wednesdayPartialHours,
    thursdayPartialHours,
    fridayPartialDay,
    fridayPartialHours,
    saturdayPartialHours,
    sundayPartialHours,
    perDayHours,
  ]);
  const handleAddingRegularHours = async () => {
    setLoading(true);
    if (handleAddingHoursData()) {
      try {
        const res = await EmployeeService._addEmployeeRegularHors(
          employeedId || "",
          companyId,
          {
            employee: employeedId,
            hourly_paid_bool: hourlyPaid,
            hours_per_day: perDayHours,
            full_days_per_week: price,
            schedules_id: schedule,
            working_days_info: [
              {
                day: "mon",
                partial: mondayPartialDay ? true : false,
                hours: mondayPartialDay ? mondayPartialHours : null,
                day_off: !mondayChecked,
              },
              {
                day: "tue",
                partial: tuesdayPartialDay ? true : false,
                hours: tuesdayPartialDay ? tuesdayPartialHours : null,
                day_off: !tuesdayChecked,
              },
              {
                day: "wed",
                partial: wednesdayPartialDay ? true : false,
                hours: wednesdayPartialDay ? wednesdayPartialHours : null,
                day_off: !wednesdayChecked,
              },
              {
                day: "thu",
                partial: thursdayPartialDay ? true : false,
                hours: thursdayPartialDay ? thursdayPartialHours : null,
                day_off: !thursdayChecked,
              },
              {
                day: "fri",
                partial: fridayPartialDay ? true : false,
                hours: fridayPartialDay ? fridayPartialHours : null,
                day_off: !fridayChecked,
              },
              {
                day: "sat",
                partial: saturdayPartialDay ? true : false,
                hours: saturdayPartialDay ? saturdayPartialHours : null,
                day_off: !saturdayChecked,
              },
              {
                day: "sun",
                partial: sundayPartialDay ? true : false,
                hours: sundayPartialDay ? sundayPartialHours : null,
                day_off: !sundayChecked,
              },
            ],
          }
        );
        if (res.status == 200 || res.status == 201) {
          setLoading(false);
          Notify("Regular hours added Successfully", 1);
          navigate("/dashboard/employee-list");
        } else {
          Notify(`some error`, 0);
          setLoading(false);
        }
      } catch (e: any) {
        setLoading(false);
        if (e.response.status == 401) {
          // disaptch(resetUser());
        }
      }
    }
  };

  const populateFields = (data: any) => {
    const {
      address_data,
      first_name,
      last_name,
      email,
      phone,
      pay_frequency,
      name_format,
      job_title,
      date_of_birth,
      date_of_appointment,
      gender,
      company,
      is_sdl,
      is_cpf,
      is_dual_shg_deduction,
      race,
      payment_method,
      religion,
      nric,
      passport,
      malaysian_ic,
      fin,
      legal_status,
      nationality,
      start_permanent_date,
      emp_number = "",
      bank_account_number,
      bank_branch_code,
      bank_name,
    } = data;
    setIsDisable(() => true);
    setBankInfo(() => ({
      bank_name: bank_name || "",
      bank_account_number: bank_account_number || "",
      bank_branch_code: bank_branch_code || "",
    }));
    setEmployeeInfo(() => ({
      first_name,
      last_name,
      email,
      phone: phone || "",
      pay_frequency: "",
      name_format: name_format || "First Name Last Name",
      job_title,
      date_of_birth: date_of_birth,
      date_of_appointment: date_of_appointment,
      gender,
      company: companyId,
      is_sdl,
      is_cpf,
      is_dual_shg_deduction,
      race,
      payment_method,
      religion,
      emp_number,
      start_permanent_date,
    }));
    setLegalStatusInfo(() => ({
      nric,
      passport,
      malaysian_ic,
      fin,
      legal_status,
      nationality,
      start_permanent_date: start_permanent_date,
    }));
    setAddressInfo(address_data[0]);
  };

  const user = useAppSelector((state) => state.user);

  const handleNRICValidate = async () => {
    try {
      const res = await EmployeeService._validateEmployeeData(
        `nric=${legalStatusInfo.nric}`
      );
      if (res.status == 200) {
        try {
          const res = await CompanyService._getEmployeeNumber(companyId);
          if (res?.setting?.mode == "Manual") {
            // setEmployeeInfo((prev) => ({
            //   ...prev,
            //   emp_number: '',
            // }));
            setManualEmpNumber(true);
          } else {
            setManualEmpNumber(false);
          }
        } catch (e: any) {
          console.error(e);
        }
        if (res?.data?.id) {
          setConfirmationModalContent({
            title: "Continue",
            content: `This NRIC already exists with an Employee. Do you want to proceed with the same employee?`,
            cancelText: "Cancel",
            resetContent: () => setConfirmationModalContent(null),

            onConfirm: () => {
              getPayFrequency();
              populateFields(res.data);
            },
          });
        }
      }
    } catch (e: any) {
      console.error(e.response.data.is_valid, "error");

      if (e.response.status == 400) {
        if (e.response.data.is_valid == "False") {
          let nricValid = Validate.validateNRIC(legalStatusInfo.nric);
          if (nricValid) {
            updatedErrorByKey("nric", nricValid);
          } else {
            updatedErrorByKey("nric", "NRIC is not valid");
          }
        }
      }
      console.error(e);
    }
  };
  const handleFINValidate = async () => {
    try {
      const res = await EmployeeService._validateEmployeeData(
        `fin=${legalStatusInfo.fin}`
      );
      if (res.status == 200) {
        try {
          const res = await CompanyService._getEmployeeNumber(companyId);
          if (res?.setting?.mode == "Manual") {
            // setEmployeeInfo((prev) => ({
            //   ...prev,
            //   emp_number: '',
            // }));
            setManualEmpNumber(true);
          } else {
            setManualEmpNumber(false);
          }
        } catch (e: any) {
          console.error(e);
        }
        if (res.data.fin) {
          if (res?.data?.id) {
            setConfirmationModalContent({
              title: "Continue",
              content: `This FIN number already exists  with an Employee. Do you want to proceed with the same employee?`,
              cancelText: "Cancel",
              resetContent: () => setConfirmationModalContent(null),

              onConfirm: () => {
                getPayFrequency();
                populateFields(res.data);
              },
            });
          }
        } else {
          setErrorCollection({});
        }
      }
    } catch (e: any) {
      if (e.response.data.is_valid === "False") {
        let finValid = Validate.validateFIN(legalStatusInfo.fin);
        if (finValid) {
          updatedErrorByKey("fin", finValid);
        } else {
          updatedErrorByKey("fin", "Fin is not valid");
        }
      }
    }
  };

  const handleMalasiyanIcValidate = async () => {
    try {
      const res = await EmployeeService._validateEmployeeData(
        `malaysiyan_ic=${legalStatusInfo.malaysian_ic}`
      );
      if (res.status == 200) {
        try {
          const res = await CompanyService._getEmployeeNumber(companyId);
          if (res?.setting?.mode == "Manual") {
            // setEmployeeInfo((prev) => ({
            //   ...prev,
            //   emp_number: '',
            // }));
            setManualEmpNumber(true);
          } else {
            setManualEmpNumber(false);
          }
        } catch (e: any) {
          console.error(e);
        }
        if (res.data.malaysian_ic) {
          if (res?.data?.id) {
            setConfirmationModalContent({
              title: "Continue",
              content: `This Malaysian IC already exists  with an Employee. Do you want to proceed with the same employee?`,
              cancelText: "Cancel",
              resetContent: () => setConfirmationModalContent(null),

              onConfirm: () => {
                getPayFrequency();
                populateFields(res.data);
              },
            });
          }
        } else {
          setErrorCollection({});
        }
      }
    } catch (e: any) {
      if (e.response.data.is_valid === "False") {
        let finValid = Validate.validateMalaysianIC(
          legalStatusInfo.malaysian_ic
        );
        if (finValid) {
          updatedErrorByKey("malaysian_ic", finValid);
        } else {
          updatedErrorByKey("malaysian_ic", "Malaysiyan IC is not valid");
        }
      }
    }
  };

  const handlePassportValidate = async () => {
    try {
      const res = await EmployeeService._validateEmployeeData(
        `passport=${legalStatusInfo.malaysian_ic}`
      );
      if (res.status == 200) {
        try {
          const res = await CompanyService._getEmployeeNumber(companyId);
          if (res?.setting?.mode == "Manual") {
            // setEmployeeInfo((prev) => ({
            //   ...prev,
            //   emp_number: '',
            // }));
            setManualEmpNumber(true);
          } else {
            setManualEmpNumber(false);
          }
        } catch (e: any) {
          console.error(e);
        }
        if (res.data.passport) {
          if (res?.data?.id) {
            setConfirmationModalContent({
              title: "Continue",
              content: `This passport number already exists  with an Employee. Do you want to proceed with the same employee?`,
              cancelText: "Cancel",
              resetContent: () => setConfirmationModalContent(null),

              onConfirm: () => {
                getPayFrequency();
                populateFields(res.data);
              },
            });
          }
        } else {
          setErrorCollection({});
        }
      }
    } catch (e: any) {
      if (e.response.data.is_valid === "False") {
        updatedErrorByKey("passport", "Passport Number is not valid");
      }
    }
  };

  useEffect(() => {
    if (!user?.current_payrun_date) {
      setShowRedirectModal(() => true);
    }
  }, []);
  const handleEmailValidate = async () => {
    const isValid = Utils.validateEmail(employeeInfo.email);
    if (isValid) {
      setErrorCollection({});
      try {
        const res = await EmployeeService._validateEmployeeData(
          `email=${employeeInfo.email}`
        );
        try {
          const res = await CompanyService._getEmployeeNumber(companyId);
          if (res?.setting?.mode == "Manual") {
            // setEmployeeInfo((prev) => ({
            //   ...prev,
            //   emp_number: '',
            // }));
            setManualEmpNumber(true);
          } else {
            setManualEmpNumber(false);
          }
        } catch (e: any) {
          console.error(e);
        }
        if (res.status == 200) {
          if (res?.data?.id) {
            if (res?.data?.id) {
              setConfirmationModalContent({
                title: "Continue",
                content: `This Email address already exists  with an Employee. Do you want to proceed with the same employee?`,
                cancelText: "Cancel",
                resetContent: () => setConfirmationModalContent(null),

                onConfirm: () => {
                  getPayFrequency();
                  populateFields(res.data);
                },
              });
            }
          }
        }
      } catch (e: any) {
        console.error(e);
      }
    } else {
      if (employeeInfo.email == "") {
        updatedErrorByKey("email", "Email can't be blank");
      } else {
        updatedErrorByKey("email", "please enter valid email.");
      }
    }
  };
  
  const handlePhoneValidation = () => {
    let phoneValid = Validate.phoneValidate(employeeInfo?.phone);
    if (phoneValid) {
      updatedErrorByKey("phone", phoneValid);
    } else {
      updatedErrorByKey("phone");
    }
  };
  useEffect(() => {
    const option = legalStatusInfo?.legal_status;
    if (option == 1 || option == 2) {
      handleEmployeeInfoChange("is_deduction_except", false);
      handleEmployeeInfoChange("is_cpf", false);
      setCpfDeduction(false);
      setRaceList(() => raceData);
    } else if (option !== "") {
      handleEmployeeInfoChange("is_deduction_except", true);
      handleEmployeeInfoChange("is_cpf", true);
      setCpfDeduction(true);
      setRaceList(() => raceList?.filter((i: any) => i.id == 5));
      handleEmployeeInfoChange("race", "5");
    }
  }, [legalStatusInfo?.legal_status, raceList?.length]);

  const deduction= [
    {id:"1", value: 'CDAC'},
   { id:"3", value:'SINDA'},
   {id: "4", value:'ECF'}
  ];
  return step == 1 ? (
    <>
      <div className="hero_header">
        <h2>Add New Employee - step 1 of 2</h2>
      </div>
      <EmployeeHeader />
      {loading && <Loader />}

      <div>
        <div className="new-employee">
          <h4>Basic Details</h4>
        </div>
        <FormErrorMessage name="employee" errorCollection={errorCollection} />
        <CustomTextField
          title="Job Title"
          value={employeeInfo.job_title}
          onChangeText={(text) => {
            handleEmployeeInfoChange("job_title", text);
          }}
          error={
            Object.keys(errorCollection)?.includes("job_title") &&
            errorCollection.job_title
          }
          require={true}
          onFocus={() => updatedErrorByKey("job_title")}
          disabled={isDisable && !DISABLE_LIST.includes("job_title")}
        />
        <CustomSelectField
          title="Pay Frequency"
          value={employeeInfo.pay_frequency}
          onChangeOption={(option) => {
            handleEmployeeInfoChange("pay_frequency", option);
          }}
          require={true}
          option={
            (payFrequency &&
              payFrequency.length > 0 &&
              payFrequency?.map((item) => ({
                key: item.name,
                value: item.id,
              }))) ||
            []
          }
          disabled={isDisable && !DISABLE_LIST.includes("pay_frequency")}
        />
        <CustomSelectField
          title="Legal Name format"
          value={employeeInfo.name_format}
          require={true}
          onChangeOption={(option) => {
            updatedErrorByKey("name_format", "");
            handleEmployeeInfoChange("name_format", option);
          }}
          option={[
            { key: "Last Name First Name", value: "Last Name First Name" },
            { key: "First Name Last Name", value: "First Name Last Name" },
            { key: "Single Name", value: "Single Name" },
          ]}
          error={
            Object.keys(errorCollection)?.includes("name_format") &&
            errorCollection.name_format
          }
          disabled={isDisable && !DISABLE_LIST.includes("name_format")}
        />
        <CustomTextField
          title="First Name (Given Name)"
          value={employeeInfo.first_name}
          onChangeText={(text) => handleEmployeeInfoChange("first_name", text)}
          error={
            Object.keys(errorCollection)?.includes("first_name") &&
            errorCollection.first_name
          }
          onFocus={() => updatedErrorByKey("first_name")}
          require={true}
          disabled={isDisable && !DISABLE_LIST.includes("first_name")}
        />
        {employeeInfo.name_format !== "Single Name" && (
          <CustomTextField
            title="Last Name (Family Name)"
            value={employeeInfo.last_name || ""}
            onChangeText={(text) => handleEmployeeInfoChange("last_name", text)}
            error={
              Object.keys(errorCollection)?.includes("last_name") &&
              errorCollection.last_name
            }
            onFocus={() => updatedErrorByKey("last_name")}
            require={true}
            disabled={isDisable && !DISABLE_LIST.includes("last_name")}
          />
        )}
        {manualEmpNumber && (
          <CustomTextField
            title="Employee Number"
            value={employeeInfo.emp_number || ""}
            onChangeText={(text) =>
              handleEmployeeInfoChange("emp_number", text)
            }
            placeholder="0001"
            error={
              Object.keys(errorCollection)?.includes("employee_code") &&
              errorCollection.employee_code
            }
            onFocus={() => updatedErrorByKey("employee_code")}
            require={true}
            disabled={isDisable && !DISABLE_LIST.includes("emp_number")}
          />
        )}
        <CustomDateField
          label="Date of Birth (dd/mm/yyyy)"
          date={employeeInfo.date_of_birth}
          setDate={(date: any) =>
            handleEmployeeInfoChange("date_of_birth", date)
          }
          error={
            Object.keys(errorCollection)?.includes("date_of_birth") &&
            errorCollection.date_of_birth
          }
          onFocus={() => updatedErrorByKey("date_of_birth")}
          require={true}
          disabled={isDisable && !DISABLE_LIST.includes("date_of_birth")}
        />
        <CustomDateField
          label="Date of Appointment (dd/mm/yyyy)"
          date={employeeInfo.date_of_appointment || null}
          setDate={(date: any) =>
            handleEmployeeInfoChange("date_of_appointment", date)
          }
          error={
            Object.keys(errorCollection)?.includes("date_of_appointment") &&
            errorCollection.date_of_appointment
          }
          onFocus={() => updatedErrorByKey("date_of_appointment")}
          require={true}
          disabled={isDisable && !DISABLE_LIST.includes("date_of_appointment")}
        />
        <CustomSelectField
          title="Legal Status"
          placeholder={"Select Legal Status"}
          value={legalStatusInfo?.legal_status || ""}
          onChangeOption={(option) => {
            handleLegalStatusInfoChange("legal_status", option);
          }}
          option={
            legalStatus?.map((item: any) => ({
              key: item.name,
              value: item.id,
            })) || []
          }
          error={
            Object.keys(errorCollection)?.includes("legal_status") &&
            errorCollection.legal_status
          }
          onFocus={() => updatedErrorByKey("legal_status")}
          require={true}
          disabled={isDisable && !DISABLE_LIST.includes("legal_status")}
        />
        {(legalStatusInfo.legal_status == 3 ||
          legalStatusInfo.legal_status == 4 ||
          legalStatusInfo.legal_status == 5 ||
          legalStatusInfo.legal_status == 6 ||
          legalStatusInfo.legal_status === "7"
          ) && (
          <CustomeCkeckboxField
            title="SHG deduction applicable?"
            value={employeeInfo.is_shg_deduction}
            onChange={() =>
              handleEmployeeInfoChange(
                "is_shg_deduction",
                !employeeInfo.is_shg_deduction
              )
            }
          />
        )}
        {(legalStatusInfo.legal_status == 1 ||
          legalStatusInfo.legal_status == 2) && (
          <CustomTextField
            title="NRIC"
            value={legalStatusInfo.nric || ""}
            onBlur={handleNRICValidate}
            onChangeText={(text) => handleLegalStatusInfoChange("nric", text)}
            error={
              (Object.keys(errorCollection)?.includes("non_field_errors") ||
                Object.keys(errorCollection)?.includes("nric")) &&
              (errorCollection.nric || errorCollection.non_field_errors)
            }
            require={true}
            onFocus={() => updatedErrorByKey("non_field_errors")}
            disabled={isDisable && !DISABLE_LIST.includes("nric")}
          />
        )}
        {(legalStatusInfo.legal_status == 2 ||
          legalStatusInfo.legal_status == 3 ||
          legalStatusInfo.legal_status == 4 ||
          legalStatusInfo.legal_status == 6 || 
          legalStatusInfo.legal_status == 7
          ) && (
          <CustomSelectField
            title="Nationality"
            placeholder={"Select Nationality"}
            value={legalStatusInfo?.nationality || ""}
            onChangeOption={(option) => {
              handleLegalStatusInfoChange("nationality", option);
            }}
            option={
              countryList?.map((item: any) => ({
                key: item.nationality,
                value: item.id,
              })) || []
            }
            error={
              Object.keys(errorCollection)?.includes("nationality") &&
              errorCollection.nationality
            }
            onFocus={() => updatedErrorByKey("nationality")}
            require={true}
            disabled={isDisable && !DISABLE_LIST.includes("nationality")}
          />
        )}
        {legalStatusInfo.legal_status == 2 && (
          <CustomDateField
            label="Start of Permanent Status"
            date={employeeInfo.start_permanent_date || null}
            setDate={(date: any) =>
              handleEmployeeInfoChange("start_permanent_date", date)
            }
            require
            error={
              Object.keys(errorCollection)?.includes("start_permanent_date") &&
              errorCollection.start_permanent_date
            }
            onFocus={() => updatedErrorByKey("start_permanent_date")}
            disabled={
              isDisable && !DISABLE_LIST.includes("start_permanent_date")
            }
          />
        )}
        {(legalStatusInfo.legal_status == 3 ||
          legalStatusInfo.legal_status == 4) && (
          <CustomTextField
            title="FIN"
            value={legalStatusInfo.fin || ""}
            onBlur={handleFINValidate}
            onChangeText={(text) => handleLegalStatusInfoChange("fin", text)}
            require
            error={
              Object.keys(errorCollection)?.includes("fin") &&
              errorCollection.fin
            }
            onFocus={() => updatedErrorByKey("fin")}
            disabled={isDisable && !DISABLE_LIST.includes("fin")}
          />
        )}
        {legalStatusInfo.legal_status == 5 && (
          <CustomTextField
            title="Malaysian I/C"
            value={legalStatusInfo.malaysian_ic || ""}
            onBlur={handleMalasiyanIcValidate}
            onChangeText={(text) => {
              handleLegalStatusInfoChange("malaysian_ic", text);
            }}
            require
            error={
              Object.keys(errorCollection)?.includes("malaysian_ic") &&
              errorCollection.malaysian_ic
            }
            onFocus={() => updatedErrorByKey("malaysian_ic")}
            disabled={isDisable && !DISABLE_LIST.includes("malaysian_ic")}
          />
        )}
        {(legalStatusInfo.legal_status == 6 || legalStatusInfo.legal_status === "7") && (
          <CustomTextField
            title="Passport"
            value={legalStatusInfo.passport || ""}
            onBlur={handlePassportValidate}
            onChangeText={(text) => {
              handleLegalStatusInfoChange("passport", text);
            }}
            require
            error={
              Object.keys(errorCollection)?.includes("passport") &&
              errorCollection.passport
            }
            onFocus={() => updatedErrorByKey("passport")}
            disabled={isDisable && !DISABLE_LIST.includes("passport")}
          />
        )}
        <CustomSelectField
          title="Gender"
          value={employeeInfo.gender !== null ? employeeInfo.gender : ""}
          onChangeOption={(option) => {
            handleEmployeeInfoChange("gender", option);
          }}
          placeholder="Select Gender"
          option={[
            { key: "Male", value: "Male" },
            { key: "Female", value: "Female" },
          ]}
          error={
            Object.keys(errorCollection)?.includes("gender") &&
            errorCollection.gender
          }
          onFocus={() => updatedErrorByKey("gender")}
          require={true}
          disabled={isDisable && !DISABLE_LIST.includes("gender")}
        />
        <CustomSelectField
          title="Religion"
          value={employeeInfo.religion !== null ? employeeInfo.religion : ""}
          onChangeOption={(option) => {
            handleEmployeeInfoChange("religion", option);
          }}
          placeholder="Select Religion"
          option={
            relisionList?.map((item: any) => ({
              key: item.religion_name,
              value: item.id,
            })) || []
          }
          error={
            Object.keys(errorCollection)?.includes("religion") &&
            errorCollection.religion
          }
          onFocus={() => updatedErrorByKey("religion")}
      require={true}
          disabled={isDisable && !DISABLE_LIST.includes("religion")}
        />
        <CustomSelectField
          title="Race"
          value={employeeInfo.race !== null ? employeeInfo.race : ""}
          onChangeOption={(option) => {
            handleEmployeeInfoChange("race", option);
          }}
          placeholder="Select Race"
          option={
            raceList?.map((item: any) => ({
              key: item.race_name,
              value: item.id,
            })) || []
          }
          error={
            Object.keys(errorCollection)?.includes("race") &&
            errorCollection.race
          }
          onFocus={() => updatedErrorByKey("race")}
          require={true}
          disabled={isDisable && !DISABLE_LIST.includes("race")}
        />
      
        {employeeInfo?.religion === "4" && ["1","3","4"]?.includes(employeeInfo?.race || "") &&
      <CustomeCkeckboxField
        title={`Deduct ${deduction?.find((item)=>item?.id==employeeInfo?.race)?.value} for Muslim-${raceList?.find((item)=>item?.['id']==employeeInfo?.race)?.['race_name']}`}
        value={employeeInfo.is_dual_shg_deduction}
        onChange={() =>
          handleEmployeeInfoChange("is_dual_shg_deduction", !employeeInfo.is_dual_shg_deduction)
        }
      
        error={
          Object.keys(errorCollection)?.includes("is_dual_shg_deduction") &&
          errorCollection.is_dual_shg_deduction
        }
        
      />}

       {/*  */}
              <CustomeCkeckboxField
          title="SDL Exempt"
          value={employeeInfo.is_sdl}
          onChange={() =>
            handleEmployeeInfoChange("is_sdl", !employeeInfo.is_sdl)
          }
          error={
            Object.keys(errorCollection)?.includes("is_sdl") &&
            errorCollection.is_sdl
          }
          // onFocus={() => updatedErrorByKey("is_sdl")}
        />
        <CustomeCkeckboxField
          title="CPF Exempt"
          value={employeeInfo.is_cpf}
          onChange={() =>
            handleEmployeeInfoChange("is_cpf", !employeeInfo.is_cpf)
          }
          error={
            Object.keys(errorCollection)?.includes("is_cpf") &&
            errorCollection.religion
          }
          disabled={cpfDeduction}
          // onFocus={() => updatedErrorByKey("religion")}
        />
        <CustomTextField
          title="Email"
          value={employeeInfo.email}
          onBlur={handleEmailValidate}
          onChangeText={(text) => {
            validateEmail(text);
            handleEmployeeInfoChange("email", text);
          }}
          error={
            Object.keys(errorCollection)?.includes("email") &&
            errorCollection.email
          }
          require={true}
          onFocus={() => updatedErrorByKey("email")}
          disabled={isDisable && !DISABLE_LIST.includes("email")}
        />

        <CustomNumberField
          title="Hand Phone"
          value={employeeInfo.phone}
          onBlur={handlePhoneValidation}
          placehoder=""
          removeNumIcon={true}
          onChangeText={(text) => {
            validatePhone(text);
          }}
          note="enter 8 digit handphone number without +65 area code"
          error={
            Object.keys(errorCollection)?.includes("phone") &&
            errorCollection.phone
          }
          onFocus={handlePhoneValidation}
          disabled={isDisable && !DISABLE_LIST.includes("phone")}
        />
        <CustomSelectField
          title="Payment Method"
          value={
            employeeInfo.payment_method !== null
              ? employeeInfo.payment_method
              : ""
          }
          placeholder="Select Payment Method"
          onChangeOption={(option) => {
            handleEmployeeInfoChange("payment_method", option);
          }}
          option={payMethods?.map((item: any) => ({
            key: item.payment_method_name,
            value: item.id,
          }))}
          error={
            Object.keys(errorCollection)?.includes("payment_method") &&
            errorCollection.payment_method
          }
          require={true}
          onFocus={() => updatedErrorByKey("payment_method")}
          disabled={isDisable && !DISABLE_LIST.includes("payment_method")}
        />
        {`${employeeInfo.payment_method}` == "5" && (
          <div className="new-employee">
            <h4>Bank Details</h4>
          </div>
        )}
        {`${employeeInfo.payment_method}` == "5" && (
          <CustomGroupSelectField
            title="Bank Name"
            value={bankInfo.bank_name}
            placeholder="Select Bank"
            onChangeOption={(option) => {
              handleBankInfoChange("bank_name", option);
            }}
            option={bankList}
            error={
              Object.keys(errorCollection)?.includes("bank_name") &&
              errorCollection.bank_name
            }
            onFocus={() => updatedErrorByKey("bank_name")}
            require={true}
            disabled={isDisable && !DISABLE_LIST.includes("bank_name")}
          />
        )}
        {`${employeeInfo.payment_method}` == "5" && (
          <CustomTextField
            title="Account Number"
            value={bankInfo.bank_account_number || ""}
            onChangeText={(text) => {
              handleBankInfoChange("bank_account_number", text);
            }}
            error={
              Object.keys(errorCollection)?.includes("bank_account_number") &&
              errorCollection.bank_account_number
            }
            onFocus={() => updatedErrorByKey("bank_account_number")}
            require={true}
            disabled={
              isDisable && !DISABLE_LIST.includes("bank_account_number")
            }
          />
        )}
        {`${employeeInfo.payment_method}` == "5" && (
          <CustomTextField
            title="Branch Code"
            value={bankInfo.bank_branch_code || ""}
            onChangeText={(text) => {
              handleBankInfoChange("bank_branch_code", text);
            }}
            error={
              Object.keys(errorCollection)?.includes("bank_branch_code") &&
              errorCollection.bank_branch_code
            }
            onFocus={() => updatedErrorByKey("bank_branch_code")}
            require={true}
            disabled={isDisable && !DISABLE_LIST.includes("bank_branch_code")}
          />
        )}

        <div className="new-employee">
          <h4>Address</h4>
        </div>
        <CustomSelectField
          title="Type"
          value={addressInfo.address_type}
          placeholder="Select address"
          onChangeOption={(option) => {
            handleAddressChange("address_type", option);
          }}
          option={addressType?.map((item: any) => ({
            key: item.name,
            value: item.id,
          }))}
          error={
            Object.keys(errorCollection)?.includes("address_type") &&
            errorCollection.address_type
          }
          onFocus={() => updatedErrorByKey("address_type")}
          require={true}
          disabled={isDisable && !DISABLE_LIST.includes("address_type")}
        />

        {addressInfo.address_type == 1 && (
          <>
            <div className="new-employee">
              <CustomTextField
                title="Block No"
                value={addressInfo.block_no || ""}
                onChangeText={(text) => {
                  handleAddressChange("block_no", text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("block_no") &&
                  errorCollection.block_no
                }
                onFocus={() => updatedErrorByKey("block_no")}
                require={true}
                disabled={isDisable && !DISABLE_LIST.includes("block_no")}
              />
              <CustomTextField
                title="Street Name"
                value={addressInfo.street_name || ""}
                onChangeText={(text) => {
                  handleAddressChange("street_name", text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("street_name") &&
                  errorCollection.street_name
                }
                onFocus={() => updatedErrorByKey("street_name")}
                disabled={isDisable && !DISABLE_LIST.includes("street_name")}
              />
            </div>

            <div className="new-employee">
              <CustomNumberField
                title="Level No"
                placehoder=""
                value={addressInfo.level_no || ""}
                onChangeText={(text) => {
                  handleAddressChange("level_no", text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("level_no") &&
                  errorCollection.level_no
                }
                onFocus={() => updatedErrorByKey("level_no")}
                disabled={isDisable && !DISABLE_LIST.includes("level_no")}
              />
              <CustomNumberField
                title="Unit No"
                placehoder=""
                value={addressInfo.unit_no || ""}
                onChangeText={(text) => {
                  handleAddressChange("unit_no", text);
                }}
                error={
                  Object.keys(errorCollection)?.includes("unit_no") &&
                  errorCollection.unit_no
                }
                onFocus={() => updatedErrorByKey("unit_no")}
                disabled={isDisable && !DISABLE_LIST.includes("unit_no")}
              />
            </div>
          </>
        )}
        {(addressInfo.address_type == 2 || addressInfo.address_type == 3) && (
          <>
            <CustomTextField
              title="Address Line1"
              value={addressInfo.address_line_1 || ""}
              onChangeText={(text) => {
                handleAddressChange("address_line_1", text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_1") &&
                errorCollection.address_line_1
              }
              onFocus={() => updatedErrorByKey("address_line_1")}
              disabled={isDisable && !DISABLE_LIST.includes("address_line_1")}
            />
            <CustomTextField
              title="Address Line2"
              value={addressInfo.address_line_2 || ""}
              onChangeText={(text) => {
                handleAddressChange("address_line_2", text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_2") &&
                errorCollection.address_line_2
              }
              onFocus={() => updatedErrorByKey("address_line_2")}
              disabled={isDisable && !DISABLE_LIST.includes("address_line_2")}
            />
            <CustomTextField
              title="Address Line3"
              value={addressInfo.address_line_3 || ""}
              onChangeText={(text) => {
                handleAddressChange("address_line_3", text);
              }}
              error={
                Object.keys(errorCollection)?.includes("address_line_3") &&
                errorCollection.address_line_3
              }
              onFocus={() => updatedErrorByKey("address_line_3")}
              disabled={isDisable && !DISABLE_LIST.includes("address_line_3")}
            />
          </>
        )}
        {(addressInfo.address_type == 1 ||
          addressInfo.address_type == 2 ||
          addressInfo.address_type == 3) && (
          <div
            style={{
              width: addressInfo.address_type == 1 ? "50%" : "100%",
            }}
          >
            <CustomNumberField
              title="Postal Code"
              value={`${addressInfo.postal_code}`}
              placehoder=""
              removeNumIcon={true}
              onChangeText={(text) => {
                handleAddressChange("postal_code", text);
              }}
              error={
                Object.keys(errorCollection)?.includes("postal_code") &&
                errorCollection.postal_code
              }
              onFocus={() => updatedErrorByKey("postal_code")}
              require={true}
              disabled={isDisable && !DISABLE_LIST.includes("postal_code")}
            />
          </div>
        )}
        {addressInfo.address_type == 2 && (
          <CustomSelectField
            title="Country Code"
            value={addressInfo.country || ""}
            onChangeOption={(option) => {
              handleAddressChange("country", option);
            }}
            placeholder="Select Country Code"
            option={
              countryList?.map((item: any) => ({
                key: item.nationality,
                value: item.id,
              })) || []
            }
            error={
              Object.keys(errorCollection)?.includes("country") &&
              errorCollection.country
            }
            onFocus={() => updatedErrorByKey("country")}
            disabled={isDisable && !DISABLE_LIST.includes("country")}
          />
        )}
        <div className="new-employee">
          <h4>Other Statutory Info</h4>
        </div>
        
        <div
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            display: "flex",
          }}
        >
          <CustomButton
            title="Cancel"
            type={"secondary"}
            onClick={() => {
              navigate("/dashboard/employee-list");
            }}
          />
          <CustomButton title="Save" onClick={handleAddNewEmployee} disable={loading} />
        </div>
      </div>
      {confirmationModalContent && (
        <ConfirmationModal
          confirmationModalContent={confirmationModalContent}
        />
      )}
      <CommonModal
        open={showRedirectModal}
        updateData={() => {
          dispatch(updateAppSettingId(user.defaultCompnayId));
          navigate("/dashboard/app-settings/employer-details");
        }}
        body={`No payrun date is set. Please set a date before performing this action.`}
        heading="Set Payrun Date"
        conformButtonText="Set Date"
        cancelButtonText="Go Back"
        handleClose={() => navigate(-1)}
      />
    </>
  ) : (
    <>
      <h1>Add regular hours</h1>
      <EmployeeHeader />

      <CustomeCkeckboxField
        title="Hourly paid"
        value={hourlyPaid}
        onChange={() => setHourlyPaid((_) => !_)}
      />
      <CustomNumberField
        title="Hours per day"
        value={perDayHours}
        onChangeText={(val) => !val.startsWith("-") && setPerDayHours(val)}
        note={
          "Zero regular hours will result in a zero hourly rate for unpaid leave deductions."
        }
        require
      />

      <CustomSelectField
        title="Schedule"
        value={schedule}
        onChangeOption={setSchedule}
        option={[
          { key: "Fixed", value: "1" },
          { key: "Casual/ Temp", value: "2" },
        ]}
        require
      />
      <div className="regular-hours">
        <h4>Regular Working Days</h4>
      </div>

      {schedule == "1" && (
        <>
          <RegularHoursFields
            day="Mon"
            dayChecked={mondayChecked}
            partialDay={mondayPartialDay}
            partialHours={mondayPartialHours}
            setDayChecked={(val) => setMondayChecked(val)}
            setPartialDay={(val) => setMondayPartialDay(val)}
            setPartialHours={(val) => setMondayPartialHours(val)}
          />
          <RegularHoursFields
            day="Tue"
            dayChecked={tuesdayChecked}
            partialDay={tuesdayPartialDay}
            partialHours={tuesdayPartialHours}
            setDayChecked={(val) => setTuesdayChecked(val)}
            setPartialDay={(val) => setTuesdayPartialDay(val)}
            setPartialHours={(val) => setTuesdayPartialHours(val)}
          />
          <RegularHoursFields
            day="Wed"
            dayChecked={wednesdayChecked}
            partialDay={wednesdayPartialDay}
            partialHours={wednesdayPartialHours}
            setDayChecked={(val) => setWednesdayChecked(val)}
            setPartialDay={(val) => setWednesdayPartialDay(val)}
            setPartialHours={(val) => setWednesdayPartialHours(val)}
          />
          <RegularHoursFields
            day="Thu"
            dayChecked={thursdayChecked}
            partialDay={thursdayPartialDay}
            partialHours={thursdayPartialHours}
            setDayChecked={(val) => setThursdayChecked(val)}
            setPartialDay={(val) => setThursdayPartialDay(val)}
            setPartialHours={(val) => setThursdayPartialHours(val)}
          />
          <RegularHoursFields
            day="Fri"
            dayChecked={fridayChecked}
            partialDay={fridayPartialDay}
            partialHours={fridayPartialHours}
            setDayChecked={(val) => setFridayChecked(val)}
            setPartialDay={(val) => setFridayPartialDay(val)}
            setPartialHours={(val) => setFridayPartialHours(val)}
          />
          <RegularHoursFields
            day="Sat"
            dayChecked={saturdayChecked}
            partialDay={saturdayPartialDay}
            partialHours={saturdayPartialHours}
            setDayChecked={(val) => setSaturdayChecked(val)}
            setPartialDay={(val) => setSaturdayPartialDay(val)}
            setPartialHours={(val) => setSaturdayPartialHours(val)}
          />
          <RegularHoursFields
            day="Sun"
            dayChecked={sundayChecked}
            partialDay={sundayPartialDay}
            partialHours={sundayPartialHours}
            setDayChecked={(val) => setSundayChecked(val)}
            setPartialDay={(val) => setSundayPartialDay(val)}
            setPartialHours={(val) => setSundayPartialHours(val)}
          />
          <div
            className="new-employee full-days-per-week"
            style={{ alignItems: "center" }}
          >
            <label className="employee-label-extra">
              Full days per week <Require />
            </label>
            {editPrice ? (
              <>
                <input
                  className="edit-price"
                  value={price}
                  onChange={(e: any) => setPrice(e.target.value)}
                />
                <CustomButton
                  type="secondary"
                  size="small"
                  width="150px"
                  title="Cancel Override"
                  onClick={() => {
                    setEditPrice(false);
                    updateOverritePrice();
                  }}
                />
              </>
            ) : (
              <>
                <div className="price">{price}</div>
                <CustomButton
                  size="small"
                  width="80px"
                  title="Override"
                  onClick={() => setEditPrice(true)}
                />
              </>
            )}
          </div>
        </>
      )}

      <div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex" }}>
        <CustomButton
          title="Cancel"
          type={"secondary"}
          onClick={() => {
            navigate("/dashboard/employee-list");
          }}
        />
        <CustomButton title="Save" onClick={handleAddingRegularHours} disable={loading} />
      </div>
    </>
  );
};

export default AddNewEmployee;
